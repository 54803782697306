/** Margin */
.-m-1 {
    margin: -.25rem!important
}

.-m-1\.5 {
    margin: -.375rem!important
}

.-m-2 {
    margin: -.5rem!important
}

.-m-3 {
    margin: -.75rem!important
}

.-m-4 {
    margin: -1rem!important
}

.-m-6 {
    margin: -1.5rem!important
}

.m-0 {
    margin: 0!important
}

.m-0\.5 {
    margin: .125rem!important
}

.m-1 {
    margin: .25rem!important
}

.m-1\.5 {
    margin: .375rem!important
}

.m-2 {
    margin: .5rem!important
}

.m-3 {
    margin: .75rem!important
}

.m-4 {
    margin: 1rem!important
}

.m-6 {
    margin: 1.5rem!important
}

.m-8 {
    margin: 2rem!important
}

.-mx-1 {
    margin-left: -.25rem!important;
    margin-right: -.25rem!important
}

.-mx-2 {
    margin-left: -.5rem!important;
    margin-right: -.5rem!important
}

.-mx-3 {
    margin-left: -.75rem!important;
    margin-right: -.75rem!important
}

.-mx-4 {
    margin-left: -1rem!important;
    margin-right: -1rem!important
}

.-mx-5 {
    margin-left: -1.25rem!important;
    margin-right: -1.25rem!important
}

.-mx-6 {
    margin-left: -1.5rem!important;
    margin-right: -1.5rem!important
}

.-mx-8 {
    margin-left: -2rem!important;
    margin-right: -2rem!important
}

.-my-3 {
    margin-top: -.75rem!important;
    margin-bottom: -.75rem!important
}

.-my-px {
    margin-top: -1px!important;
    margin-bottom: -1px!important
}

.mx-0 {
    margin-left: 0!important;
    margin-right: 0!important
}

.mx-0\.5 {
    margin-left: .125rem!important;
    margin-right: .125rem!important
}

.mx-1 {
    margin-left: .25rem!important;
    margin-right: .25rem!important
}

.mx-1\.5 {
    margin-left: .375rem!important;
    margin-right: .375rem!important
}

.mx-2 {
    margin-left: .5rem!important;
    margin-right: .5rem!important
}

.mx-2\.5 {
    margin-left: .625rem!important;
    margin-right: .625rem!important
}

.mx-3 {
    margin-left: .75rem!important;
    margin-right: .75rem!important
}

.mx-4 {
    margin-left: 1rem!important;
    margin-right: 1rem!important
}

.mx-6 {
    margin-left: 1.5rem!important;
    margin-right: 1.5rem!important
}

.mx-8 {
    margin-left: 2rem!important;
    margin-right: 2rem!important
}

.mx-auto {
    margin-left: auto!important;
    margin-right: auto!important
}

.my-0 {
    margin-top: 0!important;
    margin-bottom: 0!important
}

.my-0\.5 {
    margin-top: .125rem!important;
    margin-bottom: .125rem!important
}

.my-1 {
    margin-top: .25rem!important;
    margin-bottom: .25rem!important
}

.my-10 {
    margin-top: 2.5rem!important;
    margin-bottom: 2.5rem!important
}

.my-2 {
    margin-top: .5rem!important;
    margin-bottom: .5rem!important
}

.my-3 {
    margin-top: .75rem!important;
    margin-bottom: .75rem!important
}

.my-4 {
    margin-top: 1rem!important;
    margin-bottom: 1rem!important
}

.my-5 {
    margin-top: 1.25rem!important;
    margin-bottom: 1.25rem!important
}

.my-6 {
    margin-top: 1.5rem!important;
    margin-bottom: 1.5rem!important
}

.my-8 {
    margin-top: 2rem!important;
    margin-bottom: 2rem!important
}

.my-px {
    margin-top: 1px!important;
    margin-bottom: 1px!important
}

.-mb-0 {
    margin-bottom: -0px!important
}

.-mb-0\.5 {
    margin-bottom: -.125rem!important
}

.-mb-2 {
    margin-bottom: -.5rem!important
}

.-mb-4 {
    margin-bottom: -1rem!important
}

.-mb-px {
    margin-bottom: -1px!important
}

.-ml-0 {
    margin-left: -0px!important
}

.-ml-0\.5 {
    margin-left: -.125rem!important
}

.-ml-1 {
    margin-left: -.25rem!important
}

.-ml-1\.5 {
    margin-left: -.375rem!important
}

.-ml-2 {
    margin-left: -.5rem!important
}

.-ml-2\.5 {
    margin-left: -.625rem!important
}

.-ml-3 {
    margin-left: -.75rem!important
}

.-ml-4 {
    margin-left: -1rem!important
}

.-ml-px {
    margin-left: -1px!important
}

.-mr-16 {
    margin-right: -4rem!important
}

.-mr-2 {
    margin-right: -.5rem!important
}

.-mr-3 {
    margin-right: -.75rem!important
}

.-mr-4 {
    margin-right: -1rem!important
}

.-mr-5 {
    margin-right: -1.25rem!important
}

.-mr-px {
    margin-right: -1px!important
}

.-mt-12 {
    margin-top: -3rem!important
}

.-mt-14 {
    margin-top: -3.5rem!important
}

.-mt-16 {
    margin-top: -4rem!important
}

.-mt-2 {
    margin-top: -.5rem!important
}

.-mt-2\.5 {
    margin-top: -.625rem!important
}

.-mt-26 {
    margin-top: -6.5rem!important
}

.-mt-3 {
    margin-top: -.75rem!important
}

.-mt-5 {
    margin-top: -1.25rem!important
}

.-mt-px {
    margin-top: -1px!important
}

.mb-0 {
    margin-bottom: 0!important
}

.mb-1 {
    margin-bottom: .25rem!important
}

.mb-1\.5 {
    margin-bottom: .375rem!important
}

.mb-10 {
    margin-bottom: 2.5rem!important
}

.mb-12 {
    margin-bottom: 3rem!important
}

.mb-2 {
    margin-bottom: .5rem!important
}

.mb-3 {
    margin-bottom: .75rem!important
}

.mb-4 {
    margin-bottom: 1rem!important
}

.mb-6 {
    margin-bottom: 1.5rem!important
}

.mb-7 {
    margin-bottom: 1.75rem!important
}

.mb-8 {
    margin-bottom: 2rem!important
}

.mb-px {
    margin-bottom: 1px!important
}

.ml-0 {
    margin-left: 0!important
}

.ml-0\.5 {
    margin-left: .125rem!important
}

.ml-1 {
    margin-left: .25rem!important
}

.ml-1\.5 {
    margin-left: .375rem!important
}

.ml-10 {
    margin-left: 2.5rem!important
}

.ml-12 {
    margin-left: 3rem!important
}

.ml-2 {
    margin-left: .5rem!important
}

.ml-2\.5 {
    margin-left: .625rem!important
}

.ml-3 {
    margin-left: .75rem!important
}

.ml-4 {
    margin-left: 1rem!important
}

.ml-6 {
    margin-left: 1.5rem!important
}

.ml-8 {
    margin-left: 2rem!important
}

.ml-auto {
    margin-left: auto!important
}

.mr-0 {
    margin-right: 0!important
}

.mr-0\.5 {
    margin-right: .125rem!important
}

.mr-1 {
    margin-right: .25rem!important
}

.mr-1\.5 {
    margin-right: .375rem!important
}

.mr-2 {
    margin-right: .5rem!important
}

.mr-3 {
    margin-right: .75rem!important
}

.mr-4 {
    margin-right: 1rem!important
}

.mr-5 {
    margin-right: 1.25rem!important
}

.mr-6 {
    margin-right: 1.5rem!important
}

.mr-8 {
    margin-right: 2rem!important
}

.mr-px {
    margin-right: 1px!important
}

.mt-0 {
    margin-top: 0!important
}

.mt-0\.5 {
    margin-top: .125rem!important
}

.mt-1 {
    margin-top: .25rem!important
}

.mt-1\.5 {
    margin-top: .375rem!important
}

.mt-10 {
    margin-top: 2.5rem!important
}

.mt-11 {
    margin-top: 2.75rem!important
}

.mt-12 {
    margin-top: 3rem!important
}

.mt-16 {
    margin-top: 4rem!important
}

.mt-2 {
    margin-top: .5rem!important
}

.mt-2\.5 {
    margin-top: .625rem!important
}

.mt-20 {
    margin-top: 5rem!important
}

.mt-24 {
    margin-top: 6rem!important
}

.mt-3 {
    margin-top: .75rem!important
}

.mt-4 {
    margin-top: 1rem!important
}

.mt-5 {
    margin-top: 1.25rem!important
}

.mt-52 {
    margin-top: 13rem!important
}

.mt-6 {
    margin-top: 1.5rem!important
}

.mt-7 {
    margin-top: 1.75rem!important
}

.mt-8 {
    margin-top: 2rem!important
}

.mt-auto {
    margin-top: auto!important
}

.mt-px {
    margin-top: 1px!important
}


.-space-x-1>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse))!important;
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)))!important
}

.-space-x-1\.5>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse))!important;
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)))!important
}

.space-x-0>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse))!important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))!important
}

.space-x-0\.5>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(.125rem * var(--tw-space-x-reverse))!important;
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)))!important
}

.space-x-1>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(.25rem * var(--tw-space-x-reverse))!important;
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)))!important
}

.space-x-16>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--tw-space-x-reverse))!important;
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))!important
}

.space-x-2>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(.5rem * var(--tw-space-x-reverse))!important;
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)))!important
}

.space-x-3>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(.75rem * var(--tw-space-x-reverse))!important;
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)))!important
}

.space-x-4>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse))!important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))!important
}

.space-x-6>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse))!important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))!important
}

.space-y-1>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)))!important;
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse))!important
}

.space-y-1\.5>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)))!important;
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse))!important
}

.space-y-2>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)))!important;
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse))!important
}

.space-y-3>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)))!important;
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse))!important
}

.space-y-4>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)))!important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))!important
}

.space-y-6>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)))!important;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))!important
}

.space-y-8>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)))!important;
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))!important
}




/** Padding */
.p-0 {
    padding: 0!important
}

.p-0\.5 {
    padding: .125rem!important
}

.p-1 {
    padding: .25rem!important
}

.p-16 {
    padding: 4rem!important
}

.p-2 {
    padding: .5rem!important
}

.p-3 {
    padding: .75rem!important
}

.p-4 {
    padding: 1rem!important
}

.p-5 {
    padding: 1.25rem!important
}

.p-6 {
    padding: 1.5rem!important
}

.p-8 {
    padding: 2rem!important
}

.px-0 {
    padding-left: 0!important;
    padding-right: 0!important
}

.px-0\.5 {
    padding-left: .125rem!important;
    padding-right: .125rem!important
}

.px-1 {
    padding-left: .25rem!important;
    padding-right: .25rem!important
}

.px-1\.5 {
    padding-left: .375rem!important;
    padding-right: .375rem!important
}

.px-12 {
    padding-left: 3rem!important;
    padding-right: 3rem!important
}

.px-16 {
    padding-left: 4rem!important;
    padding-right: 4rem!important
}

.px-2 {
    padding-left: .5rem!important;
    padding-right: .5rem!important
}

.px-2\.5 {
    padding-left: .625rem!important;
    padding-right: .625rem!important
}

.px-3 {
    padding-left: .75rem!important;
    padding-right: .75rem!important
}

.px-4 {
    padding-left: 1rem!important;
    padding-right: 1rem!important
}

.px-5 {
    padding-left: 1.25rem!important;
    padding-right: 1.25rem!important
}

.px-6 {
    padding-left: 1.5rem!important;
    padding-right: 1.5rem!important
}

.px-7 {
    padding-left: 1.75rem!important;
    padding-right: 1.75rem!important
}

.px-8 {
    padding-left: 2rem!important;
    padding-right: 2rem!important
}

.py-0 {
    padding-top: 0!important;
    padding-bottom: 0!important
}

.py-0\.5 {
    padding-top: .125rem!important;
    padding-bottom: .125rem!important
}

.py-1 {
    padding-top: .25rem!important;
    padding-bottom: .25rem!important
}

.py-10 {
    padding-top: 2.5rem!important;
    padding-bottom: 2.5rem!important
}

.py-12 {
    padding-top: 3rem!important;
    padding-bottom: 3rem!important
}

.py-2 {
    padding-top: .5rem!important;
    padding-bottom: .5rem!important
}

.py-2\.5 {
    padding-top: .625rem!important;
    padding-bottom: .625rem!important
}

.py-3 {
    padding-top: .75rem!important;
    padding-bottom: .75rem!important
}

.py-4 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important
}

.py-5 {
    padding-top: 1.25rem!important;
    padding-bottom: 1.25rem!important
}

.py-6 {
    padding-top: 1.5rem!important;
    padding-bottom: 1.5rem!important
}

.py-7 {
    padding-top: 1.75rem!important;
    padding-bottom: 1.75rem!important
}

.py-8 {
    padding-top: 2rem!important;
    padding-bottom: 2rem!important
}

.pb-0 {
    padding-bottom: 0!important
}

.pb-1 {
    padding-bottom: .25rem!important
}

.pb-12 {
    padding-bottom: 3rem!important
}

.pb-2 {
    padding-bottom: .5rem!important
}

.pb-22 {
    padding-bottom: 5.5rem!important
}

.pb-28 {
    padding-bottom: 7rem!important
}

.pb-3 {
    padding-bottom: .75rem!important
}

.pb-4 {
    padding-bottom: 1rem!important
}

.pb-5 {
    padding-bottom: 1.25rem!important
}

.pb-6 {
    padding-bottom: 1.5rem!important
}

.pb-7 {
    padding-bottom: 1.75rem!important
}

.pb-8 {
    padding-bottom: 2rem!important
}

.pl-0 {
    padding-left: 0!important
}
.pl-1 {
    padding-left: .25rem!important
}

.pl-10 {
    padding-left: 2.5rem!important
}

.pl-2 {
    padding-left: .5rem!important
}

.pl-3 {
    padding-left: .75rem!important
}

.pl-3\.5 {
    padding-left: .875rem!important
}

.pl-4 {
    padding-left: 1rem!important
}

.pl-5 {
    padding-left: 1.25rem!important
}

.pl-6 {
    padding-left: 1.5rem!important
}
.pr-0 {
    padding-right: 0!important
}
.pr-1 {
    padding-right: .25rem!important
}

.pr-2 {
    padding-right: .5rem!important
}

.pr-3 {
    padding-right: .75rem!important
}

.pr-4 {
    padding-right: 1rem!important
}

.pr-6 {
    padding-right: 1.5rem!important
}

.pr-7 {
    padding-right: 1.75rem!important
}

.pt-0 {
    padding-top: 0!important
}

.pt-1 {
    padding-top: .25rem!important
}

.pt-10 {
    padding-top: 2.5rem!important
}

.pt-12 {
    padding-top: 3rem!important
}

.pt-2 {
    padding-top: .5rem!important
}

.pt-3 {
    padding-top: .75rem!important
}

.pt-4 {
    padding-top: 1rem!important
}

.pt-5 {
    padding-top: 1.25rem!important
}

.pt-6 {
    padding-top: 1.5rem!important
}

.pt-8 {
    padding-top: 2rem!important
}

.pt-9 {
    padding-top: 2.25rem!important
}


/** Gap */

.gap-1 {
    gap: .25rem!important
}

.gap-2 {
    gap: .5rem!important
}

.gap-10 {
    gap: 2.5rem!important
}

.gap-3 {
    gap: .75rem!important
}

.gap-4 {
    gap: 1rem!important
}

.gap-6 {
    gap: 1.5rem!important
}

.gap-8 {
    gap: 2rem!important
}

.gap-x-1 {
    column-gap: .25rem!important
}

.gap-x-4 {
    column-gap: 1rem!important
}

.gap-x-6 {
    column-gap: 1.5rem!important
}

.gap-x-8 {
    column-gap: 2rem!important
}

.gap-y-1 {
    row-gap: .25rem!important
}

.gap-y-12 {
    row-gap: 3rem!important
}

.gap-y-4 {
    row-gap: 1rem!important
}

.gap-y-6 {
    row-gap: 1.5rem!important
}

.gap-y-8 {
    row-gap: 2rem!important
}

@media (min-width: 600px) {
    .sm\:m-8 {
        margin: 2rem!important
    }

    .sm\:-mx-12 {
        margin-left: -3rem!important;
        margin-right: -3rem!important
    }

    .sm\:mx-0 {
        margin-left: 0!important;
        margin-right: 0!important
    }

    .sm\:mx-0\.5 {
        margin-left: .125rem!important;
        margin-right: .125rem!important
    }

    .sm\:mx-3 {
        margin-left: .75rem!important;
        margin-right: .75rem!important
    }

    .sm\:mx-5 {
        margin-left: 1.25rem!important;
        margin-right: 1.25rem!important
    }

    .sm\:mx-8 {
        margin-left: 2rem!important;
        margin-right: 2rem!important
    }

    .sm\:my-10 {
        margin-top: 2.5rem!important;
        margin-bottom: 2.5rem!important
    }

    .sm\:my-12 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .sm\:my-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .sm\:-mr-3 {
        margin-right: -.75rem!important
    }

    .sm\:-mr-4 {
        margin-right: -1rem!important
    }

    .sm\:-mt-24 {
        margin-top: -6rem!important
    }

    .sm\:mb-0 {
        margin-bottom: 0!important
    }

    .sm\:mb-12 {
        margin-bottom: 3rem!important
    }

    .sm\:mb-18 {
        margin-bottom: 4.5rem!important
    }

    .sm\:mb-6 {
        margin-bottom: 1.5rem!important
    }

    .sm\:ml-0 {
        margin-left: 0!important
    }

    .sm\:ml-2 {
        margin-left: .5rem!important
    }

    .sm\:ml-3 {
        margin-left: .75rem!important
    }

    .sm\:ml-4 {
        margin-left: 1rem!important
    }

    .sm\:ml-8 {
        margin-left: 2rem!important
    }

    .sm\:ml-auto {
        margin-left: auto!important
    }

    .sm\:mr-10 {
        margin-right: 2.5rem!important
    }

    .sm\:mr-4 {
        margin-right: 1rem!important
    }

    .sm\:mt-0 {
        margin-top: 0!important
    }

    .sm\:mt-1 {
        margin-top: .25rem!important
    }

    .sm\:mt-10 {
        margin-top: 2.5rem!important
    }

    .sm\:mt-12 {
        margin-top: 3rem!important
    }

    .sm\:mt-16 {
        margin-top: 4rem!important
    }

    .sm\:mt-20 {
        margin-top: 5rem!important
    }

    .sm\:mt-24 {
        margin-top: 6rem!important
    }

    .sm\:mt-4 {
        margin-top: 1rem!important
    }

    .sm\:mt-6 {
        margin-top: 1.5rem!important
    }

    .sm\:mt-8 {
        margin-top: 2rem!important
    }

    .sm\:space-x-2>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0 !important;
        margin-right: calc(.5rem * var(--tw-space-x-reverse))!important;
        margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)))!important
    }

    .sm\:space-x-3>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0 !important;
        margin-right: calc(.75rem * var(--tw-space-x-reverse))!important;
        margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)))!important
    }

    .sm\:space-y-0>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0 !important;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)))!important;
        margin-bottom: calc(0px * var(--tw-space-y-reverse))!important
    }

    .sm\:p-10 {
        padding: 2.5rem!important
    }

    .sm\:p-12 {
        padding: 3rem!important
    }

    .sm\:p-16 {
        padding: 4rem!important
    }

    .sm\:p-4 {
        padding: 1rem!important
    }

    .sm\:p-6 {
        padding: 1.5rem!important
    }

    .sm\:p-8 {
        padding: 2rem!important
    }

	.sm\px-0 {
		padding-left: 0!important;
		padding-right: 0!important
	}

    .sm\:px-10 {
        padding-left: 2.5rem!important;
        padding-right: 2.5rem!important
    }

    .sm\:px-12 {
        padding-left: 3rem!important;
        padding-right: 3rem!important
    }

    .sm\:px-16 {
        padding-left: 4rem!important;
        padding-right: 4rem!important
    }

    .sm\:px-18 {
        padding-left: 4.5rem!important;
        padding-right: 4.5rem!important
    }

    .sm\:px-2 {
        padding-left: .5rem!important;
        padding-right: .5rem!important
    }

    .sm\:px-4 {
        padding-left: 1rem!important;
        padding-right: 1rem!important
    }

    .sm\:px-8 {
        padding-left: 2rem!important;
        padding-right: 2rem!important
    }

	.sm\:py-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

	.sm\:py-6 {
        padding-top: 1.5rem!important;
		padding-bottom: 1.5rem!important
    }

    .sm\:py-12 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .sm\:py-3 {
        padding-top: .75rem!important;
        padding-bottom: .75rem!important
    }

    .sm\:py-4 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .sm\:py-8 {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important
    }

    .sm\:pb-10 {
        padding-bottom: 2.5rem!important
    }

    .sm\:pb-20 {
        padding-bottom: 5rem!important
    }

    .sm\:pb-24 {
        padding-bottom: 6rem!important
    }

    .sm\:pb-28 {
        padding-bottom: 7rem!important
    }

    .sm\:pb-4 {
        padding-bottom: 1rem!important
    }

    .sm\:pb-48 {
        padding-bottom: 12rem!important
    }

    .sm\:pb-7 {
        padding-bottom: 1.75rem!important
    }

    .sm\:pb-8 {
        padding-bottom: 2rem!important
    }

    .sm\:pl-7 {
        padding-left: 1.75rem!important
    }

    .sm\:pl-8 {
        padding-left: 2rem!important
    }

    .sm\:pr-12 {
        padding-right: 3rem!important
    }

    .sm\:pr-5 {
        padding-right: 1.25rem!important
    }

    .sm\:pr-8 {
        padding-right: 2rem!important
    }

    .sm\:pt-0 {
        padding-top: 0!important
    }

    .sm\:pt-10 {
        padding-top: 2.5rem!important
    }

    .sm\:pt-12 {
        padding-top: 3rem!important
    }

    .sm\:pt-18 {
        padding-top: 4.5rem!important
    }

    .sm\:pt-20 {
        padding-top: 5rem!important
    }

    .sm\:pt-4 {
        padding-top: 1rem!important
    }

    .sm\:pt-6 {
        padding-top: 1.5rem!important
    }

}

@media (min-width: 960px) {

    .md\:mx-8 {
        margin-left: 2rem!important;
        margin-right: 2rem!important
    }

    .md\:-ml-2 {
        margin-left: -.5rem!important
    }

    .md\:mb-0 {
        margin-bottom: 0!important
    }

    .md\:mb-4 {
        margin-bottom: 1rem!important
    }

    .md\:ml-16 {
        margin-left: 4rem!important
    }

    .md\:ml-6 {
        margin-left: 1.5rem!important
    }

    .md\:mr-8 {
        margin-right: 2rem!important
    }

    .md\:mt-0 {
        margin-top: 0!important
    }

    .md\:mt-12 {
        margin-top: 3rem!important
    }

    .md\:mt-16 {
        margin-top: 4rem!important
    }

    .md\:mt-24 {
        margin-top: 6rem!important
    }

    .md\:mt-4 {
        margin-top: 1rem!important
    }

    .md\:mt-8 {
        margin-top: 2rem!important
    }

    .md\:mt-auto {
        margin-top: auto!important
    }

    .md\:p-16 {
        padding: 4rem!important
    }

    .md\:p-4 {
        padding: 1rem!important
    }

    .md\:p-8 {
        padding: 2rem!important
    }

	.md\px-0 {
		padding-left: 0!important;
		padding-right: 0!important
	}

    .md\:px-6 {
        padding-left: 1.5rem!important;
        padding-right: 1.5rem!important
    }

    .md\:px-8 {
        padding-left: 2rem!important;
        padding-right: 2rem!important
    }

    .md\:pb-12 {
        padding-bottom: 3rem!important
    }

    .md\:pb-3 {
        padding-bottom: .75rem!important
    }

    .md\:pl-6 {
        padding-left: 1.5rem!important
    }

    .md\:pr-8 {
        padding-right: 2rem!important
    }

    .md\:pt-24 {
        padding-top: 6rem!important
    }

    .md\:pt-4 {
        padding-top: 1rem!important
    }
	.md\:py-8 {
        padding-top: 2rem!important;
		padding-bottom: 2rem!important
    }
}

@media (min-width: 1280px) {


    .lg\:m-0 {
        margin: 0!important
    }

    .lg\:my-16 {
        margin-top: 4rem!important;
        margin-bottom: 4rem!important
    }

    .lg\:-mt-22 {
        margin-top: -5.5rem!important
    }

    .lg\:ml-0 {
        margin-left: 0!important
    }

    .lg\:ml-2 {
        margin-left: .5rem!important
    }

    .lg\:ml-3 {
        margin-left: .75rem!important
    }

    .lg\:ml-8 {
        margin-left: 2rem!important
    }

    .lg\:ml-auto {
        margin-left: auto!important
    }

    .lg\:mr-8 {
        margin-right: 2rem!important
    }

    .lg\:mt-0 {
        margin-top: 0!important
    }

    .lg\:mt-0\.5 {
        margin-top: .125rem!important
    }

    .lg\:mt-3 {
        margin-top: .75rem!important
    }

    .lg\:mt-4 {
        margin-top: 1rem!important
    }

    .lg\:mt-6 {
        margin-top: 1.5rem!important
    }

    .lg\:mt-auto {
        margin-top: auto!important
    }


    .lg\:p-10 {
        padding: 2.5rem!important
    }

    .lg\:p-12 {
        padding: 3rem!important
    }

    .lg\:px-10 {
        padding-left: 2.5rem!important;
        padding-right: 2.5rem!important
    }

    .lg\:px-28 {
        padding-left: 7rem!important;
        padding-right: 7rem!important
    }

    .lg\:py-12 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .lg\:py-8 {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important
    }

    .lg\:pb-28 {
        padding-bottom: 7rem!important
    }

    .lg\:pl-4 {
        padding-left: 1rem!important
    }

    .lg\:pr-0 {
        padding-right: 0!important
    }
}



@media print {
    .print\:p-0 {
        padding: 0!important
    }
}