.chip-large.MuiChip-root {
  border-radius: 20px;
  font-size: 1rem;
  height: 40px;
}
.chip-large.MuiChip-root .MuiChip-label {
  padding-left: 1rem;
  padding-right: 1rem;
}
.chip-large.MuiChip-root .MuiChip-deleteIcon {
  font-size: 1.875rem;
  margin: 0 5px 0 -6px;
  margin-right: 4px;
  margin-left: -4px;
}
