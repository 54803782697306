.-z-1 {
    z-index: -1!important
}

.z-10 {
    z-index: 10!important
}

.z-20 {
    z-index: 20!important
}

.z-49 {
    z-index: 49!important
}

.z-90 {
    z-index: 90!important
}

.z-99 {
    z-index: 99!important
}

.z-999 {
    z-index: 999!important
}