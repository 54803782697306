/** Text Alignment */
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

/** Text wrapping and overflow */
.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.text-ellipsis {
  text-overflow: ellipsis !important;
}

.whitespace-normal {
  white-space: normal !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.whitespace-pre-line {
  white-space: pre-line !important;
}

.whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

/** Word break */
.break-all {
  word-break: break-all !important;
}

/** Text Transform */
.capitalize {
  text-transform: capitalize !important;
}
.uppercase {
  text-transform: uppercase !important;
}

/** Font size */
.text-2xl {
  font-size: 1.25rem !important;
}

.text-3xl {
  font-size: 1.5rem !important;
}

.text-4xl {
  font-size: 2rem !important;
}

.text-5xl {
  font-size: 2.25rem !important;
}

.text-6xl {
  font-size: 2.5rem !important;
}

.text-7xl {
  font-size: 3rem !important;
}

.text-8xl {
  font-size: 4rem !important;
}

.text-9xl {
  font-size: 6rem !important;
}

.text-lg {
  font-size: 1rem !important;
}

.text-md {
  font-size: 0.8125rem !important;
}

.text-sm {
  font-size: 0.75rem !important;
}

.text-xl {
  font-size: 1.125rem !important;
}

.text-xs {
  font-size: 0.625rem !important;
}

/** Font weights and italics */
.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-thin {
  font-weight: 100 !important;
}

.italic {
  font-style: italic !important;
}

/** Line heights */
.leading-4 {
  line-height: 1rem !important;
}

.leading-5 {
  line-height: 1.25rem !important;
}

.leading-6 {
  line-height: 1.5rem !important;
}

.leading-7 {
  line-height: 1.75rem !important;
}

.leading-8 {
  line-height: 2rem !important;
}

.leading-9 {
  line-height: 2.25rem !important;
}

.leading-10 {
  line-height: 2.5rem !important;
}

.leading-none {
  line-height: 1 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.leading-relaxed {
  line-height: 1.625 !important;
}

.leading-tight {
  line-height: 1.25 !important;
}

/** Monospace & Serif */
.font-mono {
  font-family:
    IBM Plex Mono,
    ui-monospace,
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    Liberation Mono,
    Courier New,
    monospace !important;
}

.font-serif {
  font-family:
    ui-serif,
    Georgia,
    Cambria,
    Times New Roman,
    Times,
    serif !important;
}

/** Letter spacing */
.tracking-tight {
  letter-spacing: -0.025em !important;
}

.tracking-tighter {
  letter-spacing: -0.05em !important;
}

.tracking-wide {
  letter-spacing: 0.025em !important;
}

.tracking-wider {
  letter-spacing: 0.05em !important;
}

/** Text decoration */
.underline {
  text-decoration-line: underline !important;
}

.overline {
  text-decoration-line: overline !important;
}

.line-through {
  text-decoration-line: line-through !important;
}

.no-underline {
  text-decoration-line: none !important;
}

.hover\:underline:hover {
  text-decoration-line: underline !important;
}
.group:hover .group-hover\:underline {
  text-decoration-line: underline !important;
}

.word-wrap {
  word-wrap: break-word; /* Ensures long words are broken */
  white-space: normal; /* Allows the text to wrap to the next line */
  overflow-wrap: break-word; /* For better word-breaking in modern browsers */
}
