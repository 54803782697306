/* @import '_vars.css'; */
:root {
	--vertical-navigation-width: 280px;
	--vertical-navigation-dense-width: 80px;
	--vertical-navigation-thin-width: 80px;
	--vertical-navigation-compact-width: 112px;
  /*  
  
  --fuse-border
  --fuse-primary-400-rgb
  --fuse-primary-600-rgb
  */
}

#vertical-navigation {
  position: sticky;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  width: var(--vertical-navigation-width);
  min-width: var(--vertical-navigation-width);
  max-width: var(--vertical-navigation-width);
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  z-index: 200;
}
#vertical-navigation.vertical-navigation-animations-enabled {
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: visibility, margin-left, margin-right, transform, width,
    max-width, min-width;
}
#vertical-navigation.vertical-navigation-animations-enabled
  .vertical-navigation-wrapper {
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: width, max-width, min-width;
}
#vertical-navigation.vertical-navigation-mode-over {
  position: fixed;
  top: 0;
  bottom: 0;
}
#vertical-navigation.vertical-navigation-position-left.vertical-navigation-mode-side {
  margin-left: calc(var(--vertical-navigation-width) * -1);
}
#vertical-navigation.vertical-navigation-position-left.vertical-navigation-mode-side.vertical-navigation-opened {
  margin-left: 0;
}
#vertical-navigation.vertical-navigation-position-left.vertical-navigation-mode-over {
  left: 0;
  transform: translate3d(-100%, 0, 0);
}
#vertical-navigation.vertical-navigation-position-left.vertical-navigation-mode-over.vertical-navigation-opened {
  transform: translateZ(0);
}
#vertical-navigation.vertical-navigation-position-left
  .vertical-navigation-wrapper {
  left: 0;
}
#vertical-navigation.vertical-navigation-position-right.vertical-navigation-mode-side {
  margin-right: calc(var(--vertical-navigation-width) * -1);
}
#vertical-navigation.vertical-navigation-position-right.vertical-navigation-mode-side.vertical-navigation-opened {
  margin-right: 0;
}
#vertical-navigation.vertical-navigation-position-right.vertical-navigation-mode-over {
  right: 0;
  transform: translate3d(100%, 0, 0);
}
#vertical-navigation.vertical-navigation-position-right.vertical-navigation-mode-over.vertical-navigation-opened {
  transform: translateZ(0);
}
#vertical-navigation.vertical-navigation-position-right
  .vertical-navigation-wrapper {
  right: 0;
}
#vertical-navigation.vertical-navigation-inner {
  position: relative;
  width: auto;
  min-width: 0;
  max-width: none;
  height: auto;
  min-height: 0;
  max-height: none;
  box-shadow: none;
}
#vertical-navigation.vertical-navigation-inner .vertical-navigation-wrapper {
  position: relative;
  overflow: visible;
  height: auto;
}
#vertical-navigation.vertical-navigation-inner
  .vertical-navigation-wrapper
  .vertical-navigation-content {
  overflow: visible !important;
}
#vertical-navigation .vertical-navigation-wrapper {
  position: absolute;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  background: inherit;
  box-shadow: inset -1px 0 0 var(--fuse-border);
}
#vertical-navigation .vertical-navigation-wrapper .vertical-navigation-content {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
}
#vertical-navigation
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-divider-item {
  margin: 24px 0;
}
#vertical-navigation
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-group-item {
  margin-top: 24px;
}
#vertical-navigation .vertical-navigation-aside-wrapper {
  position: absolute;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: var(--vertical-navigation-width);
  width: var(--vertical-navigation-width);
  height: 100%;
  z-index: 5;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition-duration: 0.4s;
  transition-property: left, right;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  background: inherit;
}
#vertical-navigation
  .vertical-navigation-aside-wrapper
  > #vertical-navigation-aside-item {
  padding: 24px 0;
}
#vertical-navigation
  .vertical-navigation-aside-wrapper
  > #vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper {
  display: none !important;
}
#vertical-navigation.vertical-navigation-position-right
  .vertical-navigation-aside-wrapper {
  left: auto;
  right: var(--vertical-navigation-width);
}
#vertical-navigation #vertical-navigation-aside-item,
#vertical-navigation #vertical-navigation-basic-item,
#vertical-navigation #vertical-navigation-collapsable-item,
#vertical-navigation #vertical-navigation-divider-item,
#vertical-navigation #vertical-navigation-group-item,
#vertical-navigation #vertical-navigation-spacer-item {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  -webkit-user-select: none;
  user-select: none;
}
#vertical-navigation
  #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation
  #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation
  #vertical-navigation-divider-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation
  #vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation
  #vertical-navigation-spacer-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  border-radius: 6px;
}
#vertical-navigation
  #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item.vertical-navigation-item-disabled,
#vertical-navigation
  #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item.vertical-navigation-item-disabled,
#vertical-navigation
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item.vertical-navigation-item-disabled,
#vertical-navigation
  #vertical-navigation-divider-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item.vertical-navigation-item-disabled,
#vertical-navigation
  #vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item.vertical-navigation-item-disabled,
#vertical-navigation
  #vertical-navigation-spacer-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item.vertical-navigation-item-disabled {
  cursor: default;
  opacity: 0.4;
}
#vertical-navigation
  #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon,
#vertical-navigation
  #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon,
#vertical-navigation
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon,
#vertical-navigation
  #vertical-navigation-divider-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon,
#vertical-navigation
  #vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon,
#vertical-navigation
  #vertical-navigation-spacer-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon {
  margin-right: 16px;
}
#vertical-navigation
  #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-subtitle,
#vertical-navigation
  #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-subtitle,
#vertical-navigation
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-subtitle,
#vertical-navigation
  #vertical-navigation-divider-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-subtitle,
#vertical-navigation
  #vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-subtitle,
#vertical-navigation
  #vertical-navigation-spacer-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-subtitle {
  font-size: 11px;
  line-height: 1.5;
}
#vertical-navigation
  #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation
  #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation
  #vertical-navigation-divider-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation
  #vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation
  #vertical-navigation-spacer-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge {
  margin-left: auto;
}
#vertical-navigation
  #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge
  .vertical-navigation-item-badge-content,
#vertical-navigation
  #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge
  .vertical-navigation-item-badge-content,
#vertical-navigation
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge
  .vertical-navigation-item-badge-content,
#vertical-navigation
  #vertical-navigation-divider-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge
  .vertical-navigation-item-badge-content,
#vertical-navigation
  #vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge
  .vertical-navigation-item-badge-content,
#vertical-navigation
  #vertical-navigation-spacer-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge
  .vertical-navigation-item-badge-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
  height: 20px;
}
#vertical-navigation
  #vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper,
#vertical-navigation
  #vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper,
#vertical-navigation
  #vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper,
#vertical-navigation
  #vertical-navigation-group-item
  > .vertical-navigation-item-wrapper {
  margin: 0 12px;
}
#vertical-navigation #vertical-navigation-aside-item,
#vertical-navigation #vertical-navigation-basic-item,
#vertical-navigation #vertical-navigation-collapsable-item {
  margin-bottom: 4px;
}
#vertical-navigation #vertical-navigation-aside-item .vertical-navigation-item,
#vertical-navigation #vertical-navigation-basic-item .vertical-navigation-item,
#vertical-navigation
  #vertical-navigation-collapsable-item
  .vertical-navigation-item {
  cursor: pointer;
}
#vertical-navigation
  #vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge
  + .vertical-navigation-item-arrow {
  margin-left: 8px;
}
#vertical-navigation
  #vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-arrow {
  height: 20px;
  line-height: 20px;
  margin-left: auto;
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
    color 375ms cubic-bezier(0.25, 0.8, 0.25, 1);
}
#vertical-navigation
  #vertical-navigation-collapsable-item.vertical-navigation-item-expanded
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-arrow {
  transform: rotate(90deg);
}
#vertical-navigation
  #vertical-navigation-collapsable-item
  > .vertical-navigation-item-children
  > *:first-child {
  margin-top: 6px;
}
#vertical-navigation
  #vertical-navigation-collapsable-item
  > .vertical-navigation-item-children
  > *:last-child {
  padding-bottom: 6px;
}
#vertical-navigation
  #vertical-navigation-collapsable-item
  > .vertical-navigation-item-children
  > *:last-child
  > .vertical-navigation-item-children
  > *:last-child {
  padding-bottom: 0;
}
#vertical-navigation
  #vertical-navigation-collapsable-item
  > .vertical-navigation-item-children
  .vertical-navigation-item {
  padding: 10px 16px;
}
#vertical-navigation
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-children {
  overflow: hidden;
}
#vertical-navigation
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-children
  .vertical-navigation-item {
  padding-left: 56px;
}
#vertical-navigation
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-children
  .vertical-navigation-item-children
  .vertical-navigation-item {
  padding-left: 72px;
}
#vertical-navigation
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-children
  .vertical-navigation-item-children
  .vertical-navigation-item-children
  .vertical-navigation-item {
  padding-left: 88px;
}
#vertical-navigation
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-children
  .vertical-navigation-item-children
  .vertical-navigation-item-children
  .vertical-navigation-item-children
  .vertical-navigation-item {
  padding-left: 104px;
}
#vertical-navigation #vertical-navigation-divider-item {
  margin: 12px 0;
}
#vertical-navigation
  #vertical-navigation-divider-item
  .vertical-navigation-item-wrapper {
  height: 1px;
  box-shadow: 0 1px;
}
#vertical-navigation
  #vertical-navigation-group-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation
  #vertical-navigation-group-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon {
  display: none !important;
}
#vertical-navigation
  #vertical-navigation-group-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-title {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
#vertical-navigation #vertical-navigation-spacer-item {
  margin: 6px 0;
}
.vertical-navigation-overlay {
  position: absolute;
  inset: 0;
  z-index: 170;
  /* opacity: 0; */
  background-color: #0009;
}
.vertical-navigation-overlay + .vertical-navigation-aside-overlay {
  background-color: transparent;
}
.vertical-navigation-aside-overlay {
  position: absolute;
  inset: 0;
  z-index: 169;
  opacity: 0;
  background-color: #0000004d;
}
#vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item {
  color: currentColor;
}
#vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon,
#vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon,
#vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon,
#vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon {
  color: currentColor;
  opacity: 0.6;
}
#vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-title,
#vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-title,
#vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-title,
#vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-title {
  color: currentColor;
  opacity: 0.8;
}
#vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-subtitle,
#vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-subtitle,
#vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-subtitle,
#vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-subtitle {
  color: currentColor;
  opacity: 0.5;
}
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active,
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced {
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.05;
}
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active:is(.dark *),
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced:is(.dark *),
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active:is(.dark *),
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced:is(.dark *),
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active:is(.dark *),
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced:is(.dark *) {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.12;
}
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active
  .vertical-navigation-item-icon,
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced
  .vertical-navigation-item-icon,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active
  .vertical-navigation-item-icon,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced
  .vertical-navigation-item-icon,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active
  .vertical-navigation-item-icon,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced
  .vertical-navigation-item-icon {
  opacity: 1;
}
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active
  .vertical-navigation-item-title,
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced
  .vertical-navigation-item-title,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active
  .vertical-navigation-item-title,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced
  .vertical-navigation-item-title,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active
  .vertical-navigation-item-title,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced
  .vertical-navigation-item-title {
  opacity: 1;
}
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active
  .vertical-navigation-item-subtitle,
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced
  .vertical-navigation-item-subtitle,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active
  .vertical-navigation-item-subtitle,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced
  .vertical-navigation-item-subtitle,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active
  .vertical-navigation-item-subtitle,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(
    .vertical-navigation-item-disabled
  ).vertical-navigation-item-active-forced
  .vertical-navigation-item-subtitle {
  opacity: 1;
}
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover {
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.05;
}
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover:is(.dark *),
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover:is(.dark *),
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover:is(.dark *) {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.12;
}
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover
  .vertical-navigation-item-icon,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover
  .vertical-navigation-item-icon,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover
  .vertical-navigation-item-icon {
  opacity: 1;
}
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover
  .vertical-navigation-item-title,
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover
  .vertical-navigation-item-arrow,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover
  .vertical-navigation-item-title,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover
  .vertical-navigation-item-arrow,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover
  .vertical-navigation-item-title,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover
  .vertical-navigation-item-arrow {
  opacity: 1;
}
#vertical-navigation-aside-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover
  .vertical-navigation-item-subtitle,
#vertical-navigation-basic-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover
  .vertical-navigation-item-subtitle,
#vertical-navigation-collapsable-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:not(.vertical-navigation-item-active-forced):not(
    .vertical-navigation-item-active
  ):not(.vertical-navigation-item-disabled):hover
  .vertical-navigation-item-subtitle {
  opacity: 1;
}
#vertical-navigation-collapsable-item.vertical-navigation-item-expanded
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon {
  opacity: 1;
}
#vertical-navigation-collapsable-item.vertical-navigation-item-expanded
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title,
#vertical-navigation-collapsable-item.vertical-navigation-item-expanded
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-arrow {
  opacity: 1;
}
#vertical-navigation-collapsable-item.vertical-navigation-item-expanded
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-subtitle {
  opacity: 1;
}
#vertical-navigation-group-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-title {
  --tw-text-opacity: 1;
  color: rgba(var(--fuse-primary-600-rgb), var(--tw-text-opacity));
  opacity: 1;
}
#vertical-navigation-group-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-title:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(var(--fuse-primary-400-rgb), var(--tw-text-opacity));
}

#vertical-navigation.vertical-navigation-appearance-compact {
  width: var(--vertical-navigation-compact-width);
  min-width: var(--vertical-navigation-compact-width);
  max-width: var(--vertical-navigation-compact-width);
}
#vertical-navigation.vertical-navigation-appearance-compact.vertical-navigation-position-left.vertical-navigation-mode-side {
  margin-left: calc(var(--vertical-navigation-compact-width) * -1);
}
#vertical-navigation.vertical-navigation-appearance-compact.vertical-navigation-position-left.vertical-navigation-opened {
  margin-left: 0;
}
#vertical-navigation.vertical-navigation-appearance-compact.vertical-navigation-position-right.vertical-navigation-mode-side {
  margin-right: calc(var(--vertical-navigation-compact-width) * -1);
}
#vertical-navigation.vertical-navigation-appearance-compact.vertical-navigation-position-right.vertical-navigation-opened {
  margin-right: 0;
}
#vertical-navigation.vertical-navigation-appearance-compact.vertical-navigation-position-right
  .vertical-navigation-aside-wrapper {
  left: auto;
  right: var(--vertical-navigation-compact-width);
}
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper,
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper {
  margin: 4px 8px 0;
}
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item {
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  border-radius: 6px;
}
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon,
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon {
  margin-right: 0;
}
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper,
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper {
  margin-top: 8px;
}
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-title,
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-title {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  line-height: 16px;
}
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-subtitle,
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper
  .vertical-navigation-item-subtitle {
  display: none !important;
}
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge {
  position: absolute;
  top: 12px;
  left: 64px;
}
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  > #vertical-navigation-collapsable-item,
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  > #vertical-navigation-collapsable-item {
  display: none;
}
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  > #vertical-navigation-group-item
  > .vertical-navigation-item-wrapper,
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  > #vertical-navigation-group-item
  > .vertical-navigation-item-wrapper {
  display: none;
}
#vertical-navigation.vertical-navigation-appearance-compact
  .vertical-navigation-aside-wrapper {
  left: var(--vertical-navigation-compact-width);
}

#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-mode-over
  ) {
  width: var(--vertical-navigation-dense-width);
  min-width: var(--vertical-navigation-dense-width);
  max-width: var(--vertical-navigation-dense-width);
}
#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-mode-over
  ).vertical-navigation-position-left.vertical-navigation-mode-side {
  margin-left: calc(var(--vertical-navigation-dense-width) * -1);
}
#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-mode-over
  ).vertical-navigation-position-left.vertical-navigation-opened {
  margin-left: 0;
}
#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-mode-over
  ).vertical-navigation-position-right.vertical-navigation-mode-side {
  margin-right: calc(var(--vertical-navigation-dense-width) * -1);
}
#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-mode-over
  ).vertical-navigation-position-right.vertical-navigation-opened {
  margin-right: 0;
}
#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-mode-over
  ).vertical-navigation-position-right
  .vertical-navigation-aside-wrapper {
  left: auto;
  right: var(--vertical-navigation-dense-width);
}
#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-mode-over
  ).vertical-navigation-position-right.vertical-navigation-hover
  .vertical-navigation-aside-wrapper {
  left: auto;
  right: var(--vertical-navigation-width);
}
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item {
  width: calc(var(--vertical-navigation-dense-width) - 24px);
  min-width: calc(var(--vertical-navigation-dense-width) - 24px);
  max-width: calc(var(--vertical-navigation-dense-width) - 24px);
}
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-arrow,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-arrow,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-arrow,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-arrow,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-group-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper {
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-group-item:first-of-type {
  margin-top: 0;
}
#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-hover
  ):not(.vertical-navigation-mode-over)
  .vertical-navigation-wrapper
  .vertical-navigation-content
  .vertical-navigation-item-wrapper
  .vertical-navigation-item {
  padding: 10px 16px;
}
#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-hover
  ):not(.vertical-navigation-mode-over)
  .vertical-navigation-wrapper
  .vertical-navigation-content
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-arrow,
#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-hover
  ):not(.vertical-navigation-mode-over)
  .vertical-navigation-wrapper
  .vertical-navigation-content
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-hover
  ):not(.vertical-navigation-mode-over)
  .vertical-navigation-wrapper
  .vertical-navigation-content
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper {
  white-space: nowrap;
  opacity: 0;
}
#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-hover
  ):not(.vertical-navigation-mode-over)
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-collapsable-item
  .vertical-navigation-item-children {
  display: none;
}
#vertical-navigation.vertical-navigation-appearance-dense:not(
    .vertical-navigation-hover
  ):not(.vertical-navigation-mode-over)
  .vertical-navigation-wrapper
  .vertical-navigation-content
  #vertical-navigation-group-item
  > .vertical-navigation-item-wrapper
  .vertical-navigation-item:before {
  content: "";
  position: absolute;
  top: 20px;
  width: 23px;
  border-top-width: 2px;
}
#vertical-navigation.vertical-navigation-appearance-dense
  .vertical-navigation-aside-wrapper {
  left: var(--vertical-navigation-dense-width);
}
#vertical-navigation.vertical-navigation-appearance-dense.vertical-navigation-hover
  .vertical-navigation-wrapper {
  width: var(--vertical-navigation-width);
}
#vertical-navigation.vertical-navigation-appearance-dense.vertical-navigation-hover
  .vertical-navigation-wrapper
  .vertical-navigation-content
  .vertical-navigation-item-wrapper
  .vertical-navigation-item {
  width: calc(var(--vertical-navigation-width) - 24px);
  min-width: calc(var(--vertical-navigation-width) - 24px);
  max-width: calc(var(--vertical-navigation-width) - 24px);
}
#vertical-navigation.vertical-navigation-appearance-dense.vertical-navigation-hover
  .vertical-navigation-wrapper
  .vertical-navigation-content
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-arrow,
#vertical-navigation.vertical-navigation-appearance-dense.vertical-navigation-hover
  .vertical-navigation-wrapper
  .vertical-navigation-content
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge,
#vertical-navigation.vertical-navigation-appearance-dense.vertical-navigation-hover
  .vertical-navigation-wrapper
  .vertical-navigation-content
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper {
  white-space: nowrap;
  animation: removeWhiteSpaceNoWrap 1ms linear 0.35s;
  animation-fill-mode: forwards;
}
#vertical-navigation.vertical-navigation-appearance-dense.vertical-navigation-hover
  .vertical-navigation-aside-wrapper {
  left: var(--vertical-navigation-width);
}
@keyframes removeWhiteSpaceNoWrap {
  0% {
    white-space: nowrap;
  }
  99% {
    white-space: nowrap;
  }
  to {
    white-space: normal;
  }
}

#vertical-navigation.vertical-navigation-appearance-thin {
  width: var(--vertical-navigation-thin-width);
  min-width: var(--vertical-navigation-thin-width);
  max-width: var(--vertical-navigation-thin-width);
}
#vertical-navigation.vertical-navigation-appearance-thin.vertical-navigation-position-left.vertical-navigation-mode-side {
  margin-left: calc(var(--vertical-navigation-thin-width) * -1);
}
#vertical-navigation.vertical-navigation-appearance-thin.vertical-navigation-position-left.vertical-navigation-opened {
  margin-left: 0;
}
#vertical-navigation.vertical-navigation-appearance-thin.vertical-navigation-position-right.vertical-navigation-mode-side {
  margin-right: calc(var(--vertical-navigation-thin-width) * -1);
}
#vertical-navigation.vertical-navigation-appearance-thin.vertical-navigation-position-right.vertical-navigation-opened {
  margin-right: 0;
}
#vertical-navigation.vertical-navigation-appearance-thin.vertical-navigation-position-right
  .vertical-navigation-aside-wrapper {
  left: auto;
  right: var(--vertical-navigation-thin-width);
}
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item,
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item {
  flex-direction: column;
  justify-content: center;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  padding: 0 16px;
}
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper,
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item,
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item {
  justify-content: center;
  padding: 12px;
  border-radius: 4px;
}
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon,
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-icon {
  margin: 0;
}
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-arrow,
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge-content,
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-aside-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper,
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-arrow,
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-badge-content,
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-basic-item
  .vertical-navigation-item-wrapper
  .vertical-navigation-item
  .vertical-navigation-item-title-wrapper {
  display: none;
}
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-collapsable-item {
  display: none;
}
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-wrapper
  .vertical-navigation-content
  > #vertical-navigation-group-item
  > .vertical-navigation-item-wrapper {
  display: none;
}
#vertical-navigation.vertical-navigation-appearance-thin
  .vertical-navigation-aside-wrapper {
  left: var(--vertical-navigation-thin-width);
}
