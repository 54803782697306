
.static {
    position: static!important
}

.fixed {
    position: fixed!important
}

.\!absolute,.absolute {
    position: absolute!important
}

.relative {
    position: relative!important
}

.sticky {
    position: sticky!important
}



.inset-0 {
    inset: 0!important
}

.inset-auto {
    inset: auto!important
}

.inset-x-0 {
    left: 0!important;
    right: 0!important
}

.inset-y-0 {
    top: 0!important;
    bottom: 0!important
}

.-bottom-px {
    bottom: -1px!important
}

.-left-1 {
    left: -.25rem!important
}

.-right-1 {
    right: -.25rem!important
}

.-right-16 {
    right: -4rem!important
}

.-top-16 {
    top: -4rem!important
}

.-top-4 {
    top: -1rem!important
}

.-top-px {
    top: -1px!important
}

.bottom-0 {
    bottom: 0!important
}

.bottom-4 {
    bottom: 1rem!important
}

.left-0 {
    left: 0!important
}

.left-4 {
    left: 1rem!important
}

.left-5 {
    left: 1.25rem!important
}

.right-0 {
    right: 0!important
}

.right-1 {
    right: .25rem!important
}

.right-1\.5 {
    right: .375rem!important
}

.right-5 {
    right: 1.25rem!important
}

.top-0 {
    top: 0!important
}

.top-1 {
    top: .25rem!important
}

.top-1\.5 {
    top: .375rem!important
}

.top-1\/2 {
    top: 50%!important
}

.top-6 {
    top: 1.5rem!important
}

.top-7 {
    top: 1.75rem!important
}

/* .inset-0 {
    inset: 0!important
}

.inset-auto {
    inset: auto!important
}

.inset-x-0 {
    left: 0!important;
    right: 0!important
}

.inset-y-0 {
    top: 0!important;
    bottom: 0!important
}

.-bottom-px {
    bottom: -1px!important
}

.-left-1 {
    left: -.25rem!important
}

.-right-1 {
    right: -.25rem!important
}

.-right-16 {
    right: -4rem!important
}

.-top-16 {
    top: -4rem!important
}

.-top-4 {
    top: -1rem!important
}

.-top-px {
    top: -1px!important
}

.bottom-0 {
    bottom: 0!important
}

.bottom-4 {
    bottom: 1rem!important
}

.left-0 {
    left: 0!important
}

.left-4 {
    left: 1rem!important
}

.left-5 {
    left: 1.25rem!important
}

.right-0 {
    right: 0!important
}

.right-1 {
    right: .25rem!important
}

.right-1\.5 {
    right: .375rem!important
}

.right-5 {
    right: 1.25rem!important
}

.top-0 {
    top: 0!important
}

.top-1 {
    top: .25rem!important
}

.top-1\.5 {
    top: .375rem!important
}

.top-1\/2 {
    top: 50%!important
}

.top-6 {
    top: 1.5rem!important
}

.top-7 {
    top: 1.75rem!important
}

.place-self-center {
    place-self: center!important
}

.self-start {
    align-self: flex-start!important
}

.self-end {
    align-self: flex-end!important
}

.self-center {
    align-self: center!important
}

.justify-self-end {
    justify-self: end!important
}

.overflow-auto {
    overflow: auto!important
}

.overflow-hidden {
    overflow: hidden!important
}

.overflow-visible {
    overflow: visible!important
}

.overflow-x-auto {
    overflow-x: auto!important
}

.overflow-y-auto {
    overflow-y: auto!important
}

.overflow-y-hidden {
    overflow-y: hidden!important
}

.overflow-y-visible {
    overflow-y: visible!important
}

.overscroll-y-contain {
    overscroll-behavior-y: contain!important
} */