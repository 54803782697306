.icon-size-10 {
    width: 2.5rem!important;
    height: 2.5rem!important;
    min-width: 2.5rem!important;
    min-height: 2.5rem!important;
    font-size: 2.5rem!important;
    line-height: 2.5rem!important
}

.icon-size-10 svg {
    width: 2.5rem!important;
    height: 2.5rem!important
}

.icon-size-12 {
    width: 3rem!important;
    height: 3rem!important;
    min-width: 3rem!important;
    min-height: 3rem!important;
    font-size: 3rem!important;
    line-height: 3rem!important
}

.icon-size-12 svg {
    width: 3rem!important;
    height: 3rem!important
}

.icon-size-14 {
    width: 3.5rem!important;
    height: 3.5rem!important;
    min-width: 3.5rem!important;
    min-height: 3.5rem!important;
    font-size: 3.5rem!important;
    line-height: 3.5rem!important
}

.icon-size-14 svg {
    width: 3.5rem!important;
    height: 3.5rem!important
}

.icon-size-16 {
    width: 4rem!important;
    height: 4rem!important;
    min-width: 4rem!important;
    min-height: 4rem!important;
    font-size: 4rem!important;
    line-height: 4rem!important
}

.icon-size-16 svg {
    width: 4rem!important;
    height: 4rem!important
}

.icon-size-24 {
    width: 6rem!important;
    height: 6rem!important;
    min-width: 6rem!important;
    min-height: 6rem!important;
    font-size: 6rem!important;
    line-height: 6rem!important
}

.icon-size-24 svg {
    width: 6rem!important;
    height: 6rem!important
}

.icon-size-3 {
    width: .75rem!important;
    height: .75rem!important;
    min-width: .75rem!important;
    min-height: .75rem!important;
    font-size: .75rem!important;
    line-height: .75rem!important
}

.icon-size-3 svg {
    width: .75rem!important;
    height: .75rem!important
}

.icon-size-3\.5 {
    width: .875rem!important;
    height: .875rem!important;
    min-width: .875rem!important;
    min-height: .875rem!important;
    font-size: .875rem!important;
    line-height: .875rem!important
}

.icon-size-3\.5 svg {
    width: .875rem!important;
    height: .875rem!important
}

.icon-size-4 {
    width: 1rem!important;
    height: 1rem!important;
    min-width: 1rem!important;
    min-height: 1rem!important;
    font-size: 1rem!important;
    line-height: 1rem!important
}

.icon-size-4 svg {
    width: 1rem!important;
    height: 1rem!important
}

.icon-size-5 {
    width: 1.25rem!important;
    height: 1.25rem!important;
    min-width: 1.25rem!important;
    min-height: 1.25rem!important;
    font-size: 1.25rem!important;
    line-height: 1.25rem!important
}

.icon-size-5 svg {
    width: 1.25rem!important;
    height: 1.25rem!important
}

.icon-size-6 {
    width: 1.5rem!important;
    height: 1.5rem!important;
    min-width: 1.5rem!important;
    min-height: 1.5rem!important;
    font-size: 1.5rem!important;
    line-height: 1.5rem!important
}

.icon-size-6 svg {
    width: 1.5rem!important;
    height: 1.5rem!important
}

.icon-size-7 {
    width: 1.75rem!important;
    height: 1.75rem!important;
    min-width: 1.75rem!important;
    min-height: 1.75rem!important;
    font-size: 1.75rem!important;
    line-height: 1.75rem!important
}

.icon-size-7 svg {
    width: 1.75rem!important;
    height: 1.75rem!important
}

.icon-size-8 {
    width: 2rem!important;
    height: 2rem!important;
    min-width: 2rem!important;
    min-height: 2rem!important;
    font-size: 2rem!important;
    line-height: 2rem!important
}

.icon-size-8 svg {
    width: 2rem!important;
    height: 2rem!important
}