/** Direction */
.flex-row {
    flex-direction: row!important
}
.flex-col {
    flex-direction: column!important
}
.flex-col-reverse {
    flex-direction: column-reverse!important
}


/** Align items */
.items-start {
    align-items: flex-start!important
}
.items-end {
    align-items: flex-end!important
}
.items-center {
    align-items: center!important
}
.items-baseline {
    align-items: baseline!important
}

.self-start {
    align-self: flex-start!important
}

.self-end {
    align-self: flex-end!important
}

.self-center {
    align-self: center!important
}


/** Justify content */
.justify-start {
    justify-content: flex-start!important
}
.justify-end {
    justify-content: flex-end!important
}
.justify-center {
    justify-content: center!important
}
.justify-between {
    justify-content: space-between!important
}
.justify-items-start {
    justify-items: start!important
}

.justify-self-end {
    justify-self: end!important
}

/** Grow and Shrink */
.flex-0 {
    flex: 0 0 auto!important
}

.flex-1 {
    flex: 1 1 0%!important
}

.flex-auto {
    flex: 1 1 auto!important
}

.grow {
    flex-grow: 1!important
}

.shrink {
    flex-shrink: 1!important
}
.shrink-0 {
    flex-shrink: 0!important
}

/** Order */
.order-1 {
    order: 1!important
}
.order-2 {
    order: 2!important
}
.order-first {
    order: -9999!important
}
.order-last {
    order: 9999!important
}

/** Wrap */
.flex-wrap {
    flex-wrap: wrap!important
}

.place-items-start {
    place-items: start!important
}

.place-self-center {
    place-self: center!important
}

