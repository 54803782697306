.opacity-0 {
    opacity: 0!important
}

.opacity-100 {
    opacity: 1!important
}

.opacity-12 {
    opacity: .12!important
}

.opacity-25 {
    opacity: .25!important
}

.opacity-40 {
    opacity: .4!important
}

.opacity-50 {
    opacity: .5!important
}

.opacity-70 {
    opacity: .7!important
}

.opacity-80 {
    opacity: .8!important
}

.hover\:opacity-80:hover {
    opacity: .8!important
}

.group:hover .group-hover\:opacity-0 {
    opacity: 0!important
}

.group:hover .group-hover\:opacity-100 {
    opacity: 1!important
}

/** Text */
.text-opacity-12 {
    --tw-text-opacity: .12 !important
}

.text-opacity-38 {
    --tw-text-opacity: .38 !important
}

.text-opacity-50 {
    --tw-text-opacity: .5 !important
}

.text-opacity-60 {
    --tw-text-opacity: .6 !important
}

.text-opacity-70 {
    --tw-text-opacity: .7 !important
}

