@import '../_vars.css';

:root {
	--mat-sidenav-content-text-color: var(--fuse-text-default);
	--mat-sidenav-content-background-color: var(--fuse-bg-default);
	--mat-sidenav-scrim-color: rgba(var(--fuse-bg-default-rgb), 0.6);
	--mat-sidenav-container-text-color: var(--fuse-text-default);
	--mat-sidenav-container-elevation-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	--mat-sidenav-container-background-color: var(--fuse-bg-default);
	--mat-sidenav-container-shape: 0;
	--mat-sidenav-container-shape: 0;
	--mat-sidenav-container-width: auto;
}

@media screen and (min-width: 600px) {
	:root {
		--mat-sidenav-container-width: auto;
	}
}

.mat-drawer-container {
  position: relative;
  z-index: 1;
  color: var(--mat-sidenav-content-text-color);
  background-color: var(--mat-sidenav-content-background-color);
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow: hidden;
}
.mat-drawer-container[fullscreen] {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.mat-drawer-container[fullscreen].mat-drawer-container-has-open {
  overflow: hidden;
}
.mat-drawer-container.mat-drawer-container-explicit-backdrop .mat-drawer-side {
  z-index: 3;
}
.mat-drawer-container.ng-animate-disabled .mat-drawer-backdrop,
.mat-drawer-container.ng-animate-disabled .mat-drawer-content,
.ng-animate-disabled .mat-drawer-container .mat-drawer-backdrop,
.ng-animate-disabled .mat-drawer-container .mat-drawer-content {
  transition: none;
}
.mat-drawer-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: block;
  z-index: 3;
  visibility: hidden;
}
.mat-drawer-backdrop.mat-drawer-shown {
  visibility: visible;
  background-color: var(--mat-sidenav-scrim-color);
}
.mat-drawer-transition .mat-drawer-backdrop {
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: background-color, visibility;
}
.cdk-high-contrast-active .mat-drawer-backdrop {
  opacity: 0.5;
}
.mat-drawer-content {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: auto;
}
.mat-drawer-transition .mat-drawer-content {
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform, margin-left, margin-right;
}
.mat-drawer {
  position: relative;
  z-index: 4;
  color: var(--mat-sidenav-container-text-color);
  box-shadow: var(--mat-sidenav-container-elevation-shadow);
  background-color: var(--mat-sidenav-container-background-color);
  border-top-right-radius: var(--mat-sidenav-container-shape);
  border-bottom-right-radius: var(--mat-sidenav-container-shape);
  width: var(--mat-sidenav-container-width);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3;
  outline: 0;
  box-sizing: border-box;
  overflow-y: auto;
  transform: translate3d(-100%, 0, 0);
}
.cdk-high-contrast-active .mat-drawer,
.cdk-high-contrast-active [dir="rtl"] .mat-drawer.mat-drawer-end {
  border-right: solid 1px currentColor;
}
.cdk-high-contrast-active [dir="rtl"] .mat-drawer,
.cdk-high-contrast-active .mat-drawer.mat-drawer-end {
  border-left: solid 1px currentColor;
  border-right: none;
}
.mat-drawer.mat-drawer-side {
  /* z-index: 2; */
}
.mat-drawer.mat-drawer-end {
  right: 0;
  transform: translate3d(100%, 0, 0);
  border-top-left-radius: var(--mat-sidenav-container-shape);
  border-bottom-left-radius: var(--mat-sidenav-container-shape);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[dir="rtl"] .mat-drawer {
  border-top-left-radius: var(--mat-sidenav-container-shape);
  border-bottom-left-radius: var(--mat-sidenav-container-shape);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transform: translate3d(100%, 0, 0);
}
[dir="rtl"] .mat-drawer.mat-drawer-end {
  border-top-right-radius: var(--mat-sidenav-container-shape);
  border-bottom-right-radius: var(--mat-sidenav-container-shape);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  left: 0;
  right: auto;
  transform: translate3d(-100%, 0, 0);
}
.mat-drawer[style*="visibility: hidden"] {
  display: none;
}
.mat-drawer-side {
  box-shadow: none;
  border-right-color: var(--mat-sidenav-container-divider-color);
  border-right-width: 1px;
  border-right-style: solid;
}
.mat-drawer-side.mat-drawer-end {
  border-left-color: var(--mat-sidenav-container-divider-color);
  border-left-width: 1px;
  border-left-style: solid;
  border-right: none;
}
[dir="rtl"] .mat-drawer-side {
  border-left-color: var(--mat-sidenav-container-divider-color);
  border-left-width: 1px;
  border-left-style: solid;
  border-right: none;
}
[dir="rtl"] .mat-drawer-side.mat-drawer-end {
  border-right-color: var(--mat-sidenav-container-divider-color);
  border-right-width: 1px;
  border-right-style: solid;
  border-left: none;
}
.mat-drawer-inner-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mat-sidenav-fixed {
  position: fixed;
}
