.auto-cols-max {
    grid-auto-columns: max-content!important
}

.grid-flow-row {
    grid-auto-flow: row!important
}

.grid-flow-col {
    grid-auto-flow: column!important
}

.auto-rows-fr {
    grid-auto-rows: minmax(0,1fr)!important
}

.grid-cols-1 {
    grid-template-columns: repeat(1,minmax(0,1fr))!important
}

.grid-cols-12 {
    grid-template-columns: repeat(12,minmax(0,1fr))!important
}

.grid-cols-2 {
    grid-template-columns: repeat(2,minmax(0,1fr))!important
}

.grid-cols-3 {
    grid-template-columns: repeat(3,minmax(0,1fr))!important
}

.grid-cols-4 {
    grid-template-columns: repeat(4,minmax(0,1fr))!important
}

.grid-rows-2 {
    grid-template-rows: repeat(2,minmax(0,1fr))!important
}

.col-span-10 {
    grid-column: span 10 / span 10!important
}

.col-span-12 {
    grid-column: span 12 / span 12!important
}

.col-span-2 {
    grid-column: span 2 / span 2!important
}

.col-span-4 {
    grid-column: span 4 / span 4!important
}

.col-span-8 {
    grid-column: span 8 / span 8!important
}

.col-span-full {
    grid-column: 1 / -1!important
}

@media (min-width: 600px) {
	.sm\:col-span-1 {
        grid-column: span 1 / span 1!important
    }

    .sm\:col-span-2 {
        grid-column: span 2 / span 2!important
    }

    .sm\:col-span-3 {
        grid-column: span 3 / span 3!important
    }

    .sm\:col-span-4 {
        grid-column: span 4 / span 4!important
    }

    .sm\:col-span-6 {
        grid-column: span 6 / span 6!important
    }

	.sm\:grid-flow-col {
        grid-auto-flow: column!important
    }

    .sm\:grid-cols-2 {
        grid-template-columns: repeat(2,minmax(0,1fr))!important
    }

    .sm\:grid-cols-3 {
        grid-template-columns: repeat(3,minmax(0,1fr))!important
    }

    .sm\:grid-cols-4 {
        grid-template-columns: repeat(4,minmax(0,1fr))!important
    }

    .sm\:grid-cols-6 {
        grid-template-columns: repeat(6,minmax(0,1fr))!important
    }

}
@media (min-width: 960px) {
    .md\:grid-cols-4 {
        grid-template-columns: repeat(4,minmax(0,1fr))!important
    }
	.md\:grid-cols-12 {
        grid-template-columns: repeat(12,minmax(0,1fr))!important
    }

	.md\:col-span-1 {
        grid-column: span 1 / span 1!important
    }

    .md\:col-span-2 {
        grid-column: span 2 / span 2!important
    }

    .md\:col-span-3 {
        grid-column: span 3 / span 3!important
    }

	.md\:col-span-4 {
        grid-column: span 4 / span 4!important
    }
	
	.md\:col-span-5 {
        grid-column: span 5 / span 5!important
    }

	.md\:col-span-7 {
        grid-column: span 7 / span 7!important
    }

	.md\:col-span-8 {
        grid-column: span 8 / span 8!important
    }
}
@media (min-width: 1280px) {
	.lg\:grid-cols-4 {
        grid-template-columns: repeat(4,minmax(0,1fr))!important
    }
}
@media (min-width: 1440px) {
    .xl\:col-span-2 {
        grid-column:span 2 / span 2!important
    }

    .xl\:grid-flow-row {
        grid-auto-flow: row!important
    }

    .xl\:grid-cols-2 {
        grid-template-columns: repeat(2,minmax(0,1fr))!important
    }

    .xl\:grid-cols-3 {
        grid-template-columns: repeat(3,minmax(0,1fr))!important
    }

    .xl\:grid-cols-5 {
        grid-template-columns: repeat(5,minmax(0,1fr))!important
    }
}