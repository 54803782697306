.line-clamp-1 {
    overflow: hidden!important;
    display: -webkit-box!important;
    -webkit-box-orient: vertical!important;
    -webkit-line-clamp: 1!important
}

.line-clamp-2 {
    overflow: hidden!important;
    display: -webkit-box!important;
    -webkit-box-orient: vertical!important;
    -webkit-line-clamp: 2!important
}

.line-clamp-3 {
    overflow: hidden!important;
    display: -webkit-box!important;
    -webkit-box-orient: vertical!important;
    -webkit-line-clamp: 3!important
}

.block {
    display: block!important
}

.inline-block {
    display: inline-block!important
}

.inline {
    display: inline!important
}

.flex {
    display: flex!important
}

.inline-flex {
    display: inline-flex!important
}

.table {
    display: table!important
}

.grid {
    display: grid!important
}

.contents {
    display: contents!important
}

.hidden {
    display: none!important
}

.aspect-\[9\/6\] {
    aspect-ratio: 9/6!important
}

.group:hover .group-hover\:flex {
    display: flex!important
}