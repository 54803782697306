abbr:where([title]) {
    text-decoration: underline dotted
}

h1,h2,h3,h4,h5,h6 {
    font-size: inherit;
    font-weight: inherit
}

a {
    color: inherit;
    text-decoration: inherit
}

b,strong {
    font-weight: bolder
}

code,kbd,samp,pre {
    font-family: IBM Plex Mono,ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

.text-default {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-text-default-rgb),var(--tw-text-opacity))!important
}

.text-secondary {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-text-secondary-rgb),var(--tw-text-opacity))!important
}

.text-hint {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-text-hint-rgb),var(--tw-text-opacity))!important
}

.text-disabled {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-text-disabled-rgb),var(--tw-text-opacity))!important
}

/** List Styles **/
.list-inside {
    list-style-position: inside!important
}

.list-decimal {
    list-style-type: decimal!important
}




