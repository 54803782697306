@import '../_vars.css';

.bg-accent {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-accent-rgb),var(--tw-bg-opacity))!important
}

.bg-accent-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-accent-100-rgb),var(--tw-bg-opacity))!important
}

.bg-accent-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-accent-200-rgb),var(--tw-bg-opacity))!important
}

.bg-accent-300 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-accent-300-rgb),var(--tw-bg-opacity))!important
}

.bg-accent-900 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-accent-900-rgb),var(--tw-bg-opacity))!important
}

.bg-amber-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 243 199 / var(--tw-bg-opacity))!important
}

.bg-amber-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(253 230 138 / var(--tw-bg-opacity))!important
}

.bg-amber-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 251 235 / var(--tw-bg-opacity))!important
}

.bg-amber-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity))!important
}

.bg-amber-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity))!important
}

.bg-black {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity))!important
}

.bg-black\/10 {
    background-color: #0000001a!important
}

.bg-blue-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity))!important
}

.bg-blue-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity))!important
}

.bg-blue-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity))!important
}

.bg-blue-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity))!important
}

.bg-blue-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity))!important
}

.bg-blue-gradient {
	background: rgb(0,75,141);
	background: linear-gradient(0deg, rgba(0,75,141,1) 0%, rgba(0,146,206,1) 100%);
}

.bg-gray-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))!important
}

.bg-gray-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))!important
}

.bg-gray-300 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity))!important
}

.bg-gray-400 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity))!important
}

.bg-gray-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity))!important
}

.bg-gray-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity))!important
}

.bg-gray-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(71 85 105 / var(--tw-bg-opacity))!important
}

.bg-gray-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity))!important
}

.bg-gray-800 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity))!important
}

.bg-gray-900 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity))!important
}

.bg-green-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity))!important
}

.bg-green-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity))!important
}

.bg-green-400 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity))!important
}

.bg-green-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity))!important
}

.bg-green-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity))!important
}

.bg-green-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity))!important
}

.bg-indigo-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(224 231 255 / var(--tw-bg-opacity))!important
}

.bg-indigo-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity))!important
}

.bg-indigo-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity))!important
}

.bg-indigo-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity))!important
}

.bg-indigo-800 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(55 48 163 / var(--tw-bg-opacity))!important
}

.bg-indigo-900 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(49 46 129 / var(--tw-bg-opacity))!important
}

.bg-orange-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity))!important
}

.bg-orange-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 215 170 / var(--tw-bg-opacity))!important
}

.bg-orange-400 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity))!important
}

.bg-orange-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity))!important
}

.bg-pink-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity))!important
}

.bg-pink-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(236 72 153 / var(--tw-bg-opacity))!important
}

.bg-pink-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(219 39 119 / var(--tw-bg-opacity))!important
}

.bg-primary {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-rgb),var(--tw-bg-opacity))!important
}

.bg-primary-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-100-rgb),var(--tw-bg-opacity))!important
}

.bg-primary-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-200-rgb),var(--tw-bg-opacity))!important
}

.bg-primary-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-50-rgb),var(--tw-bg-opacity))!important
}

.bg-primary-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-600-rgb),var(--tw-bg-opacity))!important
}

.bg-primary-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-700-rgb),var(--tw-bg-opacity))!important
}

.bg-purple-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity))!important
}

.bg-purple-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity))!important
}

.bg-red-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity))!important
}

.bg-red-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity))!important
}

.bg-red-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity))!important
}

.bg-red-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity))!important
}

.bg-red-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity))!important
}

.bg-teal-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(204 251 241 / var(--tw-bg-opacity))!important
}

.bg-teal-400 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(45 212 191 / var(--tw-bg-opacity))!important
}

.bg-teal-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(20 184 166 / var(--tw-bg-opacity))!important
}

.bg-teal-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity))!important
}

.bg-transparent {
    background-color: transparent!important
}

.bg-warn {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-warn-rgb),var(--tw-bg-opacity))!important
}

.bg-warn-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-warn-100-rgb),var(--tw-bg-opacity))!important
}

.bg-warn-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-warn-200-rgb),var(--tw-bg-opacity))!important
}

.bg-warn-400 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-warn-400-rgb),var(--tw-bg-opacity))!important
}

.bg-warn-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-warn-50-rgb),var(--tw-bg-opacity))!important
}

.bg-warn-800 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-warn-800-rgb),var(--tw-bg-opacity))!important
}

.bg-white {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))!important
}

.bg-yellow-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity))!important
}

.bg-yellow-300 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity))!important
}

.bg-yellow-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity))!important
}

.bg-opacity-12 {
    --tw-bg-opacity: .12 !important
}

.bg-opacity-5 {
    --tw-bg-opacity: .05 !important
}

.bg-opacity-50 {
    --tw-bg-opacity: .5 !important
}

.bg-opacity-60 {
    --tw-bg-opacity: .6 !important
}

.bg-opacity-80 {
    --tw-bg-opacity: .8 !important
}

.bg-opacity-90 {
    --tw-bg-opacity: .9 !important
}


.bg-card {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-bg-card-rgb),var(--tw-bg-opacity))!important
}

.bg-default {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-bg-default-rgb),var(--tw-bg-opacity))!important
}

.bg-dialog {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-bg-dialog-rgb),var(--tw-bg-opacity))!important
}

.ring-bg-default {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(var(--fuse-bg-default-rgb), var(--tw-ring-opacity)) !important
}

.ring-bg-card {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(var(--fuse-bg-card-rgb), var(--tw-ring-opacity)) !important
}

.bg-hover {
    background-color: var(--fuse-bg-hover)!important
}

.hover\:bg-hover:hover {
    background-color: var(--fuse-bg-hover)!important
}

.dark\:bg-card:is(.dark *) {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-bg-card-rgb),var(--tw-bg-opacity))!important
}

.dark\:bg-default:is(.dark *) {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-bg-default-rgb),var(--tw-bg-opacity))!important
}

.dark\:bg-hover:is(.dark *) {
    background-color: var(--fuse-bg-hover)!important
}

.dark\:hover\:bg-hover:hover:is(.dark *) {
    background-color: var(--fuse-bg-hover)!important
}

.focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))!important
}

.hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))!important
}

.hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity))!important
}

.hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity))!important
}

.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity))!important
}

.hover\:bg-opacity-20:hover {
    --tw-bg-opacity: .2 !important
}

.group:hover .group-hover\:bg-primary-800 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-800-rgb),var(--tw-bg-opacity))!important
}

@media (min-width: 600px) {
    .sm\:bg-card {
        --tw-bg-opacity: 1 !important;
        background-color: rgba(var(--fuse-bg-card-rgb),var(--tw-bg-opacity))!important
    }
}


.bg-accent {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-accent-rgb),var(--tw-bg-opacity))!important
}

.bg-accent-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-accent-100-rgb),var(--tw-bg-opacity))!important
}

.bg-accent-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-accent-200-rgb),var(--tw-bg-opacity))!important
}

.bg-accent-300 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-accent-300-rgb),var(--tw-bg-opacity))!important
}

.bg-accent-900 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-accent-900-rgb),var(--tw-bg-opacity))!important
}

.bg-amber-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 243 199 / var(--tw-bg-opacity))!important
}

.bg-amber-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(253 230 138 / var(--tw-bg-opacity))!important
}

.bg-amber-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 251 235 / var(--tw-bg-opacity))!important
}

.bg-amber-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity))!important
}

.bg-amber-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity))!important
}

.bg-black {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity))!important
}

.bg-black\/10 {
    background-color: #0000001a!important
}

.bg-blue-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity))!important
}

.bg-blue-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity))!important
}

.bg-blue-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity))!important
}

.bg-blue-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity))!important
}

.bg-blue-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity))!important
}

.bg-gray-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))!important
}

.bg-gray-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))!important
}

.bg-gray-300 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity))!important
}

.bg-gray-400 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity))!important
}

.bg-gray-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity))!important
}

.bg-gray-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity))!important
}

.bg-gray-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(71 85 105 / var(--tw-bg-opacity))!important
}

.bg-gray-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity))!important
}

.bg-gray-800 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity))!important
}

.bg-gray-900 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity))!important
}

.bg-green-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity))!important
}

.bg-green-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity))!important
}

.bg-green-400 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity))!important
}

.bg-green-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity))!important
}

.bg-green-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity))!important
}

.bg-green-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity))!important
}

.bg-indigo-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(224 231 255 / var(--tw-bg-opacity))!important
}

.bg-indigo-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity))!important
}

.bg-indigo-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity))!important
}

.bg-indigo-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity))!important
}

.bg-indigo-800 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(55 48 163 / var(--tw-bg-opacity))!important
}

.bg-indigo-900 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(49 46 129 / var(--tw-bg-opacity))!important
}

.bg-orange-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity))!important
}

.bg-orange-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 215 170 / var(--tw-bg-opacity))!important
}

.bg-orange-400 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity))!important
}

.bg-orange-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity))!important
}

.bg-pink-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity))!important
}

.bg-pink-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(236 72 153 / var(--tw-bg-opacity))!important
}

.bg-pink-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(219 39 119 / var(--tw-bg-opacity))!important
}

.bg-primary {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-rgb),var(--tw-bg-opacity))!important
}

.bg-primary-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-100-rgb),var(--tw-bg-opacity))!important
}

.bg-primary-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-200-rgb),var(--tw-bg-opacity))!important
}

.bg-primary-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-50-rgb),var(--tw-bg-opacity))!important
}

.bg-primary-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-600-rgb),var(--tw-bg-opacity))!important
}

.bg-primary-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-primary-700-rgb),var(--tw-bg-opacity))!important
}

.bg-purple-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity))!important
}

.bg-purple-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity))!important
}

.bg-red-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity))!important
}

.bg-red-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity))!important
}

.bg-red-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity))!important
}

.bg-red-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity))!important
}

.bg-red-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity))!important
}

.bg-teal-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(204 251 241 / var(--tw-bg-opacity))!important
}

.bg-teal-400 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(45 212 191 / var(--tw-bg-opacity))!important
}

.bg-teal-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(20 184 166 / var(--tw-bg-opacity))!important
}

.bg-teal-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity))!important
}

.bg-transparent {
    background-color: transparent!important
}

.bg-warn {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-warn-rgb),var(--tw-bg-opacity))!important
}

.bg-warn-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-warn-100-rgb),var(--tw-bg-opacity))!important
}

.bg-warn-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-warn-200-rgb),var(--tw-bg-opacity))!important
}

.bg-warn-400 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-warn-400-rgb),var(--tw-bg-opacity))!important
}

.bg-warn-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-warn-50-rgb),var(--tw-bg-opacity))!important
}

.bg-warn-800 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-warn-800-rgb),var(--tw-bg-opacity))!important
}

.bg-white {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))!important
}

.bg-yellow-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity))!important
}

.bg-yellow-300 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity))!important
}

.bg-yellow-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity))!important
}

.bg-opacity-12 {
    --tw-bg-opacity: .12 !important
}

.bg-opacity-5 {
    --tw-bg-opacity: .05 !important
}

.bg-opacity-50 {
    --tw-bg-opacity: .5 !important
}

.bg-opacity-60 {
    --tw-bg-opacity: .6 !important
}

.bg-opacity-80 {
    --tw-bg-opacity: .8 !important
}

.bg-opacity-90 {
    --tw-bg-opacity: .9 !important
}

/* Tomra Colors */
.bg-wind {
	--tw-bg-opacity: 1 !important;
	background-color: rgba(var(--color-secondary-wind-rgb), var(--tw-bg-opacity))!important
}