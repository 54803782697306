@import '../_vars.css';

.MuiOutlinedInput-root {
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.MuiOutlinedInput-notchedOutline {
	border-radius: 6px;
	border-width: 1px !important;
	border-style: solid;
	--tw-border-opacity: 1;
	border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
	--tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
	--tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    --tw-border-opacity: 1;
	border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	--tw-border-opacity: 1 !important;
    border-color: rgba(var(--primary-rgb), var(--tw-border-opacity)) !important;
}

.mat-dense .MuiOutlinedInput-root {
	height: 40px;
	padding-top: 18px;
	padding-bottom: 18px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-rounded .MuiOutlinedInput-notchedOutline {
    border-radius: 24px;
}
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-dense.mat-rounded .MuiOutlinedInput-notchedOutline {
    border-radius: 20px;
}