@import "../_vars.css";

.btn-mat-icon {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 50%;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 0.875rem;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  line-height: 1;
  margin: 0;
  max-height: 40px;
  min-height: 40px;
  min-width: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: center;
  text-decoration: none;
  transition:
    all 200ms cubic-bezier(0.35, 0, 0.25, 1),
    background-color 200ms cubic-bezier(0.35, 0, 0.25, 1);
  vertical-align: baseline;
  white-space: nowrap;
  width: 40px;
}
.btn-mat-icon:hover {
  background-color: rgb(148 163 184 / 0.2);
  transition:
    all 200ms cubic-bezier(0.35, 0, 0.25, 1),
    background-color 200ms cubic-bezier(0.35, 0, 0.25, 1);
}

.mdc-touch-target-wrapper {
  display: inline;
}
.mdc-elevation-overlay {
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  opacity: var(--mdc-elevation-overlay-opacity, 0);
  transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: none;
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0);
  text-transform: capitalize !important;
}
.mdc-button .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-button:active {
  outline: none;
}
.mdc-button:hover {
  background-color: var(--fuse-primary-600) !important;
  cursor: pointer;
}
.mdc-button:disabled {
  cursor: default;
  pointer-events: none;
}
.mdc-button[hidden] {
  display: none;
}
.mdc-button .mdc-button__icon {
  margin-left: 0;
  margin-right: 8px;
  display: inline-block;
  position: relative;
  vertical-align: top;
}
[dir="rtl"] .mdc-button .mdc-button__icon,
.mdc-button .mdc-button__icon[dir="rtl"] {
  margin-left: 8px;
  margin-right: 0;
}
.mdc-button .mdc-button__progress-indicator {
  font-size: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  line-height: initial;
}
.mdc-button .mdc-button__label {
  position: relative;
}
.mdc-button .mdc-button__focus-ring {
  pointer-events: none;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  display: none;
}
@media screen and (forced-colors: active) {
  .mdc-button .mdc-button__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-button .mdc-button__focus-ring::after {
  content: "";
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-button .mdc-button__focus-ring::after {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring,
  .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring {
    display: block;
  }
}
.mdc-button .mdc-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.mdc-button__label + .mdc-button__icon {
  margin-left: 8px;
  margin-right: 0;
}
[dir="rtl"] .mdc-button__label + .mdc-button__icon,
.mdc-button__label + .mdc-button__icon[dir="rtl"] {
  margin-left: 0;
  margin-right: 8px;
}
svg.mdc-button__icon {
  fill: currentColor;
}
.mdc-button--touch {
  margin-top: 6px;
  margin-bottom: 6px;
}
.mdc-button {
  padding: 0 8px 0 8px;
}
.mdc-button--unelevated {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 16px 0 16px;
}
.mdc-button--unelevated.mdc-button--icon-trailing {
  padding: 0 12px 0 16px;
}
.mdc-button--unelevated.mdc-button--icon-leading {
  padding: 0 16px 0 12px;
}
.mdc-button--raised {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 16px 0 16px;
}
.mdc-button--raised.mdc-button--icon-trailing {
  padding: 0 12px 0 16px;
}
.mdc-button--raised.mdc-button--icon-leading {
  padding: 0 16px 0 12px;
}
.mdc-button--outlined {
  border-style: solid;
  transition: border 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-button--outlined .mdc-button__ripple {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
}
.mat-mdc-button {
  font-family: var(--mdc-text-button-label-text-font);
  font-family:
    "Inter",
    ui-sans-serif,
    system-ui,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    Segoe UI Symbol,
    "Noto Color Emoji" !important;
  font-size: var(--mdc-text-button-label-text-size);
  letter-spacing: var(--mdc-text-button-label-text-tracking);
  font-weight: var(--mdc-text-button-label-text-weight);
  text-transform: var(--mdc-text-button-label-text-transform);
  height: var(--mdc-text-button-container-height);
  border-radius: var(--mdc-text-button-container-shape);
  padding: 0 var(--mat-text-button-horizontal-padding, 8px);
}
.mat-mdc-button:not(:disabled) {
  color: var(--mdc-text-button-label-text-color);
}
.mat-mdc-button:disabled {
  color: var(--mdc-text-button-disabled-label-text-color);
}
.mat-mdc-button .mdc-button__ripple {
  border-radius: var(--mdc-text-button-container-shape);
}
.mat-mdc-button:has(.material-icons, mat-icon, [matButtonIcon]) {
  padding: 0 var(--mat-text-button-with-icon-horizontal-padding, 8px);
}
.mat-mdc-button > .mat-icon {
  margin-right: var(--mat-text-button-icon-spacing, 8px);
  margin-left: var(--mat-text-button-icon-offset, 0);
}
[dir="rtl"] .mat-mdc-button > .mat-icon {
  margin-right: var(--mat-text-button-icon-offset, 0);
  margin-left: var(--mat-text-button-icon-spacing, 8px);
}
.mat-mdc-button .mdc-button__label + .mat-icon {
  margin-right: var(--mat-text-button-icon-offset, 0);
  margin-left: var(--mat-text-button-icon-spacing, 8px);
}
[dir="rtl"] .mat-mdc-button .mdc-button__label + .mat-icon {
  margin-right: var(--mat-text-button-icon-spacing, 8px);
  margin-left: var(--mat-text-button-icon-offset, 0);
}
.mat-mdc-button .mat-ripple-element {
  background-color: var(--mat-text-button-ripple-color);
}
.mat-mdc-button .mat-mdc-button-persistent-ripple::before {
  background-color: var(--mat-text-button-state-layer-color);
}
.mat-mdc-button.mat-mdc-button-disabled
  .mat-mdc-button-persistent-ripple::before {
  background-color: var(--mat-text-button-disabled-state-layer-color);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: var(--mat-text-button-hover-state-layer-opacity);
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-button.mat-mdc-button-disabled-interactive:focus
  .mat-mdc-button-persistent-ripple::before {
  opacity: var(--mat-text-button-focus-state-layer-opacity);
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: var(--mat-text-button-pressed-state-layer-opacity);
}
.mat-mdc-button .mat-mdc-button-touch-target {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: var(--mat-text-button-touch-target-display);
}
.mat-mdc-button[disabled],
.mat-mdc-button.mat-mdc-button-disabled {
  cursor: default;
  pointer-events: none;
  color: var(--mdc-text-button-disabled-label-text-color);
}
.mat-mdc-button.mat-mdc-button-disabled-interactive {
  pointer-events: auto;
}
.mat-mdc-unelevated-button {
  font-family: var(--mdc-filled-button-label-text-font);
  font-size: var(--mdc-filled-button-label-text-size);
  letter-spacing: var(--mdc-filled-button-label-text-tracking);
  font-weight: var(--mdc-filled-button-label-text-weight);
  text-transform: var(--mdc-filled-button-label-text-transform);
  height: var(--mdc-filled-button-container-height);
  border-radius: var(--mdc-filled-button-container-shape);
  padding: 0 var(--mat-filled-button-horizontal-padding, 16px);
}
.mat-mdc-unelevated-button:not(:disabled) {
  background-color: var(--mdc-filled-button-container-color);
}
.mat-mdc-unelevated-button:disabled {
  background-color: var(--mdc-filled-button-disabled-container-color);
}
.mat-mdc-unelevated-button:not(:disabled) {
  color: var(--mdc-filled-button-label-text-color);
}
.mat-mdc-unelevated-button:disabled {
  color: var(--mdc-filled-button-disabled-label-text-color);
}
.mat-mdc-unelevated-button .mdc-button__ripple {
  border-radius: var(--mdc-filled-button-container-shape);
}
.mat-mdc-unelevated-button > .mat-icon {
  margin-right: var(--mat-filled-button-icon-spacing, 8px);
  margin-left: var(--mat-filled-button-icon-offset, -4px);
}
[dir="rtl"] .mat-mdc-unelevated-button > .mat-icon {
  margin-right: var(--mat-filled-button-icon-offset, -4px);
  margin-left: var(--mat-filled-button-icon-spacing, 8px);
}
.mat-mdc-unelevated-button .mdc-button__label + .mat-icon {
  margin-right: var(--mat-filled-button-icon-offset, -4px);
  margin-left: var(--mat-filled-button-icon-spacing, 8px);
}
[dir="rtl"] .mat-mdc-unelevated-button .mdc-button__label + .mat-icon {
  margin-right: var(--mat-filled-button-icon-spacing, 8px);
  margin-left: var(--mat-filled-button-icon-offset, -4px);
}
.mat-mdc-unelevated-button .mat-ripple-element {
  background-color: var(--mat-filled-button-ripple-color);
}
.mat-mdc-unelevated-button .mat-mdc-button-persistent-ripple::before {
  background-color: var(--mat-filled-button-state-layer-color);
}
.mat-mdc-unelevated-button.mat-mdc-button-disabled
  .mat-mdc-button-persistent-ripple::before {
  background-color: var(--mat-filled-button-disabled-state-layer-color);
}
.mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: var(--mat-filled-button-hover-state-layer-opacity);
}
.mat-mdc-unelevated-button.cdk-program-focused
  .mat-mdc-button-persistent-ripple::before,
.mat-mdc-unelevated-button.cdk-keyboard-focused
  .mat-mdc-button-persistent-ripple::before,
.mat-mdc-unelevated-button.mat-mdc-button-disabled-interactive:focus
  .mat-mdc-button-persistent-ripple::before {
  opacity: var(--mat-filled-button-focus-state-layer-opacity);
}
.mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: var(--mat-filled-button-pressed-state-layer-opacity);
}
.mat-mdc-unelevated-button .mat-mdc-button-touch-target {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: var(--mat-filled-button-touch-target-display);
}
.mat-mdc-unelevated-button[disabled],
.mat-mdc-unelevated-button.mat-mdc-button-disabled {
  cursor: default;
  pointer-events: none;
  color: var(--mdc-filled-button-disabled-label-text-color);
  background-color: var(--mdc-filled-button-disabled-container-color);
}
.mat-mdc-unelevated-button.mat-mdc-button-disabled-interactive {
  pointer-events: auto;
}
.mat-mdc-raised-button {
  font-family: var(--mdc-protected-button-label-text-font);
  font-size: var(--mdc-protected-button-label-text-size);
  letter-spacing: var(--mdc-protected-button-label-text-tracking);
  font-weight: var(--mdc-protected-button-label-text-weight);
  text-transform: var(--mdc-protected-button-label-text-transform);
  height: var(--mdc-protected-button-container-height);
  border-radius: var(--mdc-protected-button-container-shape);
  padding: 0 var(--mat-protected-button-horizontal-padding, 16px);
  box-shadow: var(--mdc-protected-button-container-elevation-shadow);
}
.mat-mdc-raised-button:not(:disabled) {
  background-color: var(--mdc-protected-button-container-color);
}
.mat-mdc-raised-button:disabled {
  background-color: var(--mdc-protected-button-disabled-container-color);
}
.mat-mdc-raised-button:not(:disabled) {
  color: var(--mdc-protected-button-label-text-color);
}
.mat-mdc-raised-button:disabled {
  color: var(--mdc-protected-button-disabled-label-text-color);
}
.mat-mdc-raised-button .mdc-button__ripple {
  border-radius: var(--mdc-protected-button-container-shape);
}
.mat-mdc-raised-button > .mat-icon {
  margin-right: var(--mat-protected-button-icon-spacing, 8px);
  margin-left: var(--mat-protected-button-icon-offset, -4px);
}
[dir="rtl"] .mat-mdc-raised-button > .mat-icon {
  margin-right: var(--mat-protected-button-icon-offset, -4px);
  margin-left: var(--mat-protected-button-icon-spacing, 8px);
}
.mat-mdc-raised-button .mdc-button__label + .mat-icon {
  margin-right: var(--mat-protected-button-icon-offset, -4px);
  margin-left: var(--mat-protected-button-icon-spacing, 8px);
}
[dir="rtl"] .mat-mdc-raised-button .mdc-button__label + .mat-icon {
  margin-right: var(--mat-protected-button-icon-spacing, 8px);
  margin-left: var(--mat-protected-button-icon-offset, -4px);
}
.mat-mdc-raised-button .mat-ripple-element {
  background-color: var(--mat-protected-button-ripple-color);
}
.mat-mdc-raised-button .mat-mdc-button-persistent-ripple::before {
  background-color: var(--mat-protected-button-state-layer-color);
}
.mat-mdc-raised-button.mat-mdc-button-disabled
  .mat-mdc-button-persistent-ripple::before {
  background-color: var(--mat-protected-button-disabled-state-layer-color);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: var(--mat-protected-button-hover-state-layer-opacity);
}
.mat-mdc-raised-button.cdk-program-focused
  .mat-mdc-button-persistent-ripple::before,
.mat-mdc-raised-button.cdk-keyboard-focused
  .mat-mdc-button-persistent-ripple::before,
.mat-mdc-raised-button.mat-mdc-button-disabled-interactive:focus
  .mat-mdc-button-persistent-ripple::before {
  opacity: var(--mat-protected-button-focus-state-layer-opacity);
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: var(--mat-protected-button-pressed-state-layer-opacity);
}
.mat-mdc-raised-button .mat-mdc-button-touch-target {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: var(--mat-protected-button-touch-target-display);
}
.mat-mdc-raised-button:hover {
  box-shadow: var(--mdc-protected-button-hover-container-elevation-shadow);
}
.mat-mdc-raised-button:focus {
  box-shadow: var(--mdc-protected-button-focus-container-elevation-shadow);
}
.mat-mdc-raised-button:active,
.mat-mdc-raised-button:focus:active {
  box-shadow: var(--mdc-protected-button-pressed-container-elevation-shadow);
}
.mat-mdc-raised-button[disabled],
.mat-mdc-raised-button.mat-mdc-button-disabled {
  cursor: default;
  pointer-events: none;
  color: var(--mdc-protected-button-disabled-label-text-color);
  background-color: var(--mdc-protected-button-disabled-container-color);
}
.mat-mdc-raised-button[disabled].mat-mdc-button-disabled,
.mat-mdc-raised-button.mat-mdc-button-disabled.mat-mdc-button-disabled {
  box-shadow: var(--mdc-protected-button-disabled-container-elevation-shadow);
}
.mat-mdc-raised-button.mat-mdc-button-disabled-interactive {
  pointer-events: auto;
}
.mat-mdc-outlined-button {
  font-family: var(--mdc-outlined-button-label-text-font);
  font-size: var(--mdc-outlined-button-label-text-size);
  letter-spacing: var(--mdc-outlined-button-label-text-tracking);
  font-weight: var(--mdc-outlined-button-label-text-weight);
  text-transform: var(--mdc-outlined-button-label-text-transform);
  height: var(--mdc-outlined-button-container-height);
  border-radius: var(--mdc-outlined-button-container-shape);
  padding: 0 15px 0 15px;
  border-width: var(--mdc-outlined-button-outline-width);
  padding: 0 var(--mat-outlined-button-horizontal-padding, 15px);
}
.mat-mdc-outlined-button:not(:disabled) {
  color: var(--mdc-outlined-button-label-text-color);
}
.mat-mdc-outlined-button:disabled {
  color: var(--mdc-outlined-button-disabled-label-text-color);
}
.mat-mdc-outlined-button .mdc-button__ripple {
  border-radius: var(--mdc-outlined-button-container-shape);
}
.mat-mdc-outlined-button:not(:disabled) {
  border-color: var(--mdc-outlined-button-outline-color);
}
.mat-mdc-outlined-button:disabled {
  border-color: var(--mdc-outlined-button-disabled-outline-color);
}
.mat-mdc-outlined-button.mdc-button--icon-trailing {
  padding: 0 11px 0 15px;
}
.mat-mdc-outlined-button.mdc-button--icon-leading {
  padding: 0 15px 0 11px;
}
.mat-mdc-outlined-button .mdc-button__ripple {
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-width: var(--mdc-outlined-button-outline-width);
}
.mat-mdc-outlined-button .mdc-button__touch {
  left: calc(-1 * var(--mdc-outlined-button-outline-width));
  width: calc(100% + 2 * var(--mdc-outlined-button-outline-width));
}
.mat-mdc-outlined-button > .mat-icon {
  margin-right: var(--mat-outlined-button-icon-spacing, 8px);
  margin-left: var(--mat-outlined-button-icon-offset, -4px);
}
[dir="rtl"] .mat-mdc-outlined-button > .mat-icon {
  margin-right: var(--mat-outlined-button-icon-offset, -4px);
  margin-left: var(--mat-outlined-button-icon-spacing, 8px);
}
.mat-mdc-outlined-button .mdc-button__label + .mat-icon {
  margin-right: var(--mat-outlined-button-icon-offset, -4px);
  margin-left: var(--mat-outlined-button-icon-spacing, 8px);
}
[dir="rtl"] .mat-mdc-outlined-button .mdc-button__label + .mat-icon {
  margin-right: var(--mat-outlined-button-icon-spacing, 8px);
  margin-left: var(--mat-outlined-button-icon-offset, -4px);
}
.mat-mdc-outlined-button .mat-ripple-element {
  background-color: var(--mat-outlined-button-ripple-color);
}
.mat-mdc-outlined-button .mat-mdc-button-persistent-ripple::before {
  background-color: var(--mat-outlined-button-state-layer-color);
}
.mat-mdc-outlined-button.mat-mdc-button-disabled
  .mat-mdc-button-persistent-ripple::before {
  background-color: var(--mat-outlined-button-disabled-state-layer-color);
}
.mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: var(--mat-outlined-button-hover-state-layer-opacity);
}
.mat-mdc-outlined-button.cdk-program-focused
  .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button.cdk-keyboard-focused
  .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button.mat-mdc-button-disabled-interactive:focus
  .mat-mdc-button-persistent-ripple::before {
  opacity: var(--mat-outlined-button-focus-state-layer-opacity);
}
.mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: var(--mat-outlined-button-pressed-state-layer-opacity);
}
.mat-mdc-outlined-button .mat-mdc-button-touch-target {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: var(--mat-outlined-button-touch-target-display);
}
.mat-mdc-outlined-button[disabled],
.mat-mdc-outlined-button.mat-mdc-button-disabled {
  cursor: default;
  pointer-events: none;
  color: var(--mdc-outlined-button-disabled-label-text-color);
  border-color: var(--mdc-outlined-button-disabled-outline-color);
}
.mat-mdc-outlined-button.mat-mdc-button-disabled-interactive {
  pointer-events: auto;
}
.mat-mdc-button-base {
  text-decoration: none;
}
.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mat-mdc-button .mat-mdc-button-ripple,
.mat-mdc-button .mat-mdc-button-persistent-ripple,
.mat-mdc-button .mat-mdc-button-persistent-ripple::before,
.mat-mdc-unelevated-button .mat-mdc-button-ripple,
.mat-mdc-unelevated-button .mat-mdc-button-persistent-ripple,
.mat-mdc-unelevated-button .mat-mdc-button-persistent-ripple::before,
.mat-mdc-raised-button .mat-mdc-button-ripple,
.mat-mdc-raised-button .mat-mdc-button-persistent-ripple,
.mat-mdc-raised-button .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button .mat-mdc-button-ripple,
.mat-mdc-outlined-button .mat-mdc-button-persistent-ripple,
.mat-mdc-outlined-button .mat-mdc-button-persistent-ripple::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
}
.mat-mdc-button .mat-mdc-button-ripple,
.mat-mdc-unelevated-button .mat-mdc-button-ripple,
.mat-mdc-raised-button .mat-mdc-button-ripple,
.mat-mdc-outlined-button .mat-mdc-button-ripple {
  overflow: hidden;
}
.mat-mdc-button .mat-mdc-button-persistent-ripple::before,
.mat-mdc-unelevated-button .mat-mdc-button-persistent-ripple::before,
.mat-mdc-raised-button .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button .mat-mdc-button-persistent-ripple::before {
  content: "";
  opacity: 0;
}
.mat-mdc-button .mdc-button__label,
.mat-mdc-unelevated-button .mdc-button__label,
.mat-mdc-raised-button .mdc-button__label,
.mat-mdc-outlined-button .mdc-button__label {
  z-index: 1;
}
.mat-mdc-button .mat-mdc-focus-indicator,
.mat-mdc-unelevated-button .mat-mdc-focus-indicator,
.mat-mdc-raised-button .mat-mdc-focus-indicator,
.mat-mdc-outlined-button .mat-mdc-focus-indicator {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.mat-mdc-button:focus .mat-mdc-focus-indicator::before,
.mat-mdc-unelevated-button:focus .mat-mdc-focus-indicator::before,
.mat-mdc-raised-button:focus .mat-mdc-focus-indicator::before,
.mat-mdc-outlined-button:focus .mat-mdc-focus-indicator::before {
  content: "";
}
.mat-mdc-button._mat-animation-noopable,
.mat-mdc-unelevated-button._mat-animation-noopable,
.mat-mdc-raised-button._mat-animation-noopable,
.mat-mdc-outlined-button._mat-animation-noopable {
  transition: none !important;
  animation: none !important;
}
.mat-mdc-button > .mat-icon,
.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
  display: inline-block;
  position: relative;
  vertical-align: top;
  font-size: 1.125rem;
  height: 1.125rem;
  width: 1.125rem;
}
.mat-mdc-outlined-button .mat-mdc-button-ripple,
.mat-mdc-outlined-button .mdc-button__ripple {
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-width: -1px;
}
.mat-mdc-unelevated-button .mat-mdc-focus-indicator::before,
.mat-mdc-raised-button .mat-mdc-focus-indicator::before {
  margin: calc(
    calc(var(--mat-mdc-focus-indicator-border-width, 3px) + 2px) * -1
  );
}
.mat-mdc-outlined-button .mat-mdc-focus-indicator::before {
  margin: calc(
    calc(var(--mat-mdc-focus-indicator-border-width, 3px) + 3px) * -1
  );
}

.mdc-button.mat-unthemed:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.MuiButton-text.mdc-button.mat-warn:hover {
  background-color: rgba(220, 38, 38, 0.04) !important;
}
.mdc-button.mat-warn:not(.MuiButton-text):hover {
	background-color: #dc2626 !important;
}

/* Tabs */
.MuiTab-root {
  color: rgba(30, 41, 59, 0.6) !important;
  font-size: 1rem !important;
  text-transform: capitalize !important;
  transition: all 200ms cubic-bezier(0.35, 0, 0.25, 1);
}
.MuiTab-root:hover {
  background-color: rgba(var(--fuse-primary-100-rgb), 0.4) !important;
}
.MuiTabs-indicator {
  background-color: var(--fuse-primary-600) !important;
}
.Mui-selected {
  color: var(--fuse-primary-600) !important;
}
