.text-secondary {
    --tw-text-opacity: 1 !important;
    color: rgba(100,116,139, 1) !important;
}

.text-accent {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-accent-rgb),var(--tw-text-opacity))!important
}

.text-accent-300 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-accent-300-rgb),var(--tw-text-opacity))!important
}

.text-accent-50 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-accent-50-rgb),var(--tw-text-opacity))!important
}

.text-accent-600 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-accent-600-rgb),var(--tw-text-opacity))!important
}

.text-accent-900 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-accent-900-rgb),var(--tw-text-opacity))!important
}

.text-amber-50 {
    --tw-text-opacity: 1 !important;
    color: rgb(255 251 235 / var(--tw-text-opacity))!important
}

.text-amber-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(245 158 11 / var(--tw-text-opacity))!important
}

.text-amber-600 {
    --tw-text-opacity: 1 !important;
    color: rgb(217 119 6 / var(--tw-text-opacity))!important
}

.text-amber-800 {
    --tw-text-opacity: 1 !important;
    color: rgb(146 64 14 / var(--tw-text-opacity))!important
}

.text-black {
    --tw-text-opacity: 1 !important;
    color: rgb(0 0 0 / var(--tw-text-opacity))!important
}

.text-blue-50 {
    --tw-text-opacity: 1 !important;
    color: rgb(239 246 255 / var(--tw-text-opacity))!important
}

.text-blue-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(59 130 246 / var(--tw-text-opacity))!important
}

.text-blue-600 {
    --tw-text-opacity: 1 !important;
    color: rgb(37 99 235 / var(--tw-text-opacity))!important
}

.text-blue-800 {
    --tw-text-opacity: 1 !important;
    color: rgb(30 64 175 / var(--tw-text-opacity))!important
}

.text-current {
    color: currentColor!important
}

.text-gray-100 {
    --tw-text-opacity: 1 !important;
    color: rgb(241 245 249 / var(--tw-text-opacity))!important
}

.text-gray-200 {
    --tw-text-opacity: 1 !important;
    color: rgb(226 232 240 / var(--tw-text-opacity))!important
}

.text-gray-300 {
    --tw-text-opacity: 1 !important;
    color: rgb(203 213 225 / var(--tw-text-opacity))!important
}

.text-gray-400 {
    --tw-text-opacity: 1 !important;
    color: rgb(148 163 184 / var(--tw-text-opacity))!important
}

.text-gray-50 {
    --tw-text-opacity: 1 !important;
    color: rgb(248 250 252 / var(--tw-text-opacity))!important
}

.text-gray-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(100 116 139 / var(--tw-text-opacity))!important
}

.text-gray-600 {
    --tw-text-opacity: 1 !important;
    color: rgb(71 85 105 / var(--tw-text-opacity))!important
}

.text-gray-700 {
    --tw-text-opacity: 1 !important;
    color: rgb(51 65 85 / var(--tw-text-opacity))!important
}

.text-gray-800 {
    --tw-text-opacity: 1 !important;
    color: rgb(30 41 59 / var(--tw-text-opacity))!important
}

.text-gray-900 {
    --tw-text-opacity: 1 !important;
    color: rgb(15 23 42 / var(--tw-text-opacity))!important
}

.text-green-100 {
    --tw-text-opacity: 1 !important;
    color: rgb(220 252 231 / var(--tw-text-opacity))!important
}

.text-green-400 {
    --tw-text-opacity: 1 !important;
    color: rgb(74 222 128 / var(--tw-text-opacity))!important
}

.text-green-50 {
    --tw-text-opacity: 1 !important;
    color: rgb(240 253 244 / var(--tw-text-opacity))!important
}

.text-green-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(34 197 94 / var(--tw-text-opacity))!important
}

.text-green-600 {
    --tw-text-opacity: 1 !important;
    color: rgb(22 163 74 / var(--tw-text-opacity))!important
}

.text-green-700 {
    --tw-text-opacity: 1 !important;
    color: rgb(21 128 61 / var(--tw-text-opacity))!important
}

.text-green-800 {
    --tw-text-opacity: 1 !important;
    color: rgb(22 101 52 / var(--tw-text-opacity))!important
}

.text-indigo-50 {
    --tw-text-opacity: 1 !important;
    color: rgb(238 242 255 / var(--tw-text-opacity))!important
}

.text-indigo-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(99 102 241 / var(--tw-text-opacity))!important
}

.text-indigo-800 {
    --tw-text-opacity: 1 !important;
    color: rgb(55 48 163 / var(--tw-text-opacity))!important
}

.text-on-accent {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-on-accent-rgb),var(--tw-text-opacity))!important
}

.text-on-accent-300 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-on-accent-300-rgb),var(--tw-text-opacity))!important
}

.text-on-primary {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-on-primary-rgb),var(--tw-text-opacity))!important
}

.text-on-primary-100 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-on-primary-100-rgb),var(--tw-text-opacity))!important
}

.text-on-primary-200 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-on-primary-200-rgb),var(--tw-text-opacity))!important
}

.text-on-primary-600 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-on-primary-600-rgb),var(--tw-text-opacity))!important
}

.text-on-warn {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-on-warn-rgb),var(--tw-text-opacity))!important
}

.text-on-warn-400 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-on-warn-400-rgb),var(--tw-text-opacity))!important
}

.text-on-warn-500 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-on-warn-500-rgb),var(--tw-text-opacity))!important
}

.text-on-warn-800 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-on-warn-800-rgb),var(--tw-text-opacity))!important
}

.text-orange-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(249 115 22 / var(--tw-text-opacity))!important
}

.text-orange-800 {
    --tw-text-opacity: 1 !important;
    color: rgb(154 52 18 / var(--tw-text-opacity))!important
}

.text-pink-50 {
    --tw-text-opacity: 1 !important;
    color: rgb(253 242 248 / var(--tw-text-opacity))!important
}

.text-pink-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(236 72 153 / var(--tw-text-opacity))!important
}

.text-pink-800 {
    --tw-text-opacity: 1 !important;
    color: rgb(157 23 77 / var(--tw-text-opacity))!important
}

.text-primary {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-primary-rgb),var(--tw-text-opacity))!important
}

.text-primary-300 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-primary-300-rgb),var(--tw-text-opacity))!important
}

.text-primary-400 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-primary-400-rgb),var(--tw-text-opacity))!important
}

.text-primary-50 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-primary-50-rgb),var(--tw-text-opacity))!important
}

.text-primary-500 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-primary-500-rgb),var(--tw-text-opacity))!important
}

.text-primary-600 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-primary-600-rgb),var(--tw-text-opacity))!important
}

.text-primary-700 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-primary-700-rgb),var(--tw-text-opacity))!important
}

.text-primary-800 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-primary-800-rgb),var(--tw-text-opacity))!important
}

.text-purple-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(168 85 247 / var(--tw-text-opacity))!important
}

.text-purple-800 {
    --tw-text-opacity: 1 !important;
    color: rgb(107 33 168 / var(--tw-text-opacity))!important
}

.text-red-100 {
    --tw-text-opacity: 1 !important;
    color: rgb(254 226 226 / var(--tw-text-opacity))!important
}

.text-red-400 {
    --tw-text-opacity: 1 !important;
    color: rgb(248 113 113 / var(--tw-text-opacity))!important
}

.text-red-50 {
    --tw-text-opacity: 1 !important;
    color: rgb(254 242 242 / var(--tw-text-opacity))!important
}

.text-red-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(239 68 68 / var(--tw-text-opacity))!important
}

.text-red-600 {
    --tw-text-opacity: 1 !important;
    color: rgb(220 38 38 / var(--tw-text-opacity))!important
}

.text-red-800 {
    --tw-text-opacity: 1 !important;
    color: rgb(153 27 27 / var(--tw-text-opacity))!important
}

.text-teal-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(20 184 166 / var(--tw-text-opacity))!important
}

.text-teal-800 {
    --tw-text-opacity: 1 !important;
    color: rgb(17 94 89 / var(--tw-text-opacity))!important
}

.text-warn {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-warn-rgb),var(--tw-text-opacity))!important
}

.text-warn-50 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-warn-50-rgb),var(--tw-text-opacity))!important
}

.text-warn-600 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-warn-600-rgb),var(--tw-text-opacity))!important
}

.text-warn-800 {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-warn-800-rgb),var(--tw-text-opacity))!important
}

.text-white {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity))!important
}

.text-yellow-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(234 179 8 / var(--tw-text-opacity))!important
}

.text-yellow-800 {
    --tw-text-opacity: 1 !important;
    color: rgb(133 77 14 / var(--tw-text-opacity))!important
}

.group:hover .group-hover\:text-secondary {
    --tw-text-opacity: 1 !important;
    color: rgba(var(--fuse-text-secondary-rgb),var(--tw-text-opacity))!important
}