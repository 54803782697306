/*----------------------------------------------------

        SITE: PiggyBooks.ca - Auth
        CREATED: Mon 08/08/22
        AUTHOR: Graham Steele

----------------------------------------------------*/
:root {
  --font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --auth-primary: #1fc0da;
  --auth-primary-dark: #0094ce;
}

/*
| -------------------------------------------------------------------------
| RESET
| -------------------------------------------------------------------------
*/
body.auth {
  background-color: #f5f7f9;
  color: #484848;
  font-family: var(--font-sans-serif);
  height: 100%;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

.auth {
  /*
  | -------------------------------------------------------------------------
  | TYPOGRAPHY
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | ALERTS
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | BUTTONS
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | CARDS
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | FORMS
  | -------------------------------------------------------------------------
  */
  /* --------------------------------------------------------------------- */
}
.auth .content {
  display: block;
  padding: 3rem 0;
}
.auth a {
  color: var(--auth-primary);
  font-weight: 600;
  text-decoration: none;
  transition: all 300ms ease;
}
.auth a:hover {
  color: #484848;
  -webkit-text-decoration: solid;
          text-decoration: solid;
}
.auth .subtext {
  font-size: 0.875rem;
}
.auth .alert {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  color: inherit;
  font-size: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
}
.auth .alert-danger {
  background-color: #f8d7da;
  border-color: #f5c2c7;
  color: #842029;
}
.auth .alert-success {
  background-color: #d1e7dd;
  border-color: #badbcc;
  color: #0f5132;
}
.auth .btn {
  border: 2px solid transparent;
  border-radius: 0.25rem;
  box-shadow: none !important;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-weight: 600;
  line-height: 1;
  outline: none !important;
  padding: 16px 14px;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}
.auth .btn-block {
  width: 100%;
}
.auth .btn-primary {
  background-color: var(--auth-primary);
  border-color: var(--auth-primary);
  color: #fff;
}
.auth .btn-primary:hover {
  background-color: var(--auth-primary-dark);
  border-color: var(--auth-primary-dark);
  color: #fff;
}
.auth .page--card-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}
.auth .card {
  background-color: white;
  border-radius: 1rem;
  box-shadow: rgb(229, 231, 235) 0 4px 8px 4px;
  display: flex;
  flex-direction: column;
  margin: 1.25rem 0;
  padding: 3.5rem;
  width: 540px;
}
.auth .card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.auth .card-title .subtitle {
  font-size: 1rem;
  font-weight: 400;
}
.auth .card-form {
  text-align: left;
}
.auth div.input-block {
  margin-bottom: 1rem;
  position: relative;
}
.auth div.input-block input {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: block;
  font: inherit;
  letter-spacing: inherit;
  line-height: 1;
  margin: 0;
  min-width: 0;
  outline: none;
  padding: 16px 14px;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}
.auth div.input-block label.placeholder {
  align-items: center;
  background: none;
  color: #6c757d;
  display: flex;
  font-size: 1rem;
  left: 17px;
  line-height: 1;
  margin: 17px 0;
  padding: 0 4px;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: all 0.2s;
  transform-origin: 0 0;
}
.auth div.input-block:hover input {
  border-color: #484848;
}
.auth div.input-block:hover label.placeholder {
  color: #484848;
}
.auth div.input-block input:valid + label.placeholder,
.auth div.input-block input:focus + label.placeholder {
  transform: scale(0.8) translateY(-30px);
  background: #fff;
}
.auth div.input-block input:focus {
  border-color: #d5606d;
}
.auth div.input-block input:focus + label.placeholder {
  color: #d5606d;
  font-weight: 500;
}/*# sourceMappingURL=authStyle.css.map */