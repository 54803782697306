.visible {
    visibility: visible!important
}

.invisible {
    visibility: hidden!important
}

.collapse {
    visibility: collapse!important
}