:root {
	--font-sans-serif: 'Inter', system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

	--color-primary-sea-blue-rgb: 31,192,218;
	--color-primary-sky-blue-rgb: 0,148,206;
	--color-primary-original-blue-rgb: 0,75,114;
	--color-primary-yellow-rgb: 255,205,0;
	--color-primary-fire-orange-rgb: 242,101,34;
	--color-primary-mint-green-rgb: 110,206,178;
	--color-primary-teal-rgb: 0,150,143;
	--color-primary-light-pink-rgb: 248,163,188;
	--color-primary-warm-red-rgb: 228,0,70;

	--color-secondary-atlantic-rgb: 0,26,56;
	--color-secondary-storm-cloud-rgb: 69,93,111;
	--color-secondary-wind-rgb: 232,239,237;
	--color-secondary-black-rgb: 35,31,32;
	--color-secondary-sand-rgb: 231,231,221;
	--color-secondary-stone-rgb: 110,129,134;
	--color-secondary-limestone-rgb: 204,205,197;


	--color-primary: #f5f7f9;
	--color-primary-10: rgba(245,247,249,.1);
	--color-primary-90: rgba(245,247,249,.1);
	--color-primary: #818cf8;
	--text-default: #1e293b;
	--text-default-rgb: 30,41,59;
	--text-secondary: #64748b;
	--text-secondary-rgb: 100,116,139;
	--border-grey-rgb: 203,213,225;

	--primary-rgb: var(--color-primary-sky-blue-rgb);
}

body,
.theme-default {
	--fuse-primary-50: #e6f6fb;
	--fuse-primary-50-rgb: 230, 246, 251;
	--fuse-primary-100: #ccecf7;
	--fuse-primary-100-rgb: 204, 236, 247;
	--fuse-primary-200: #99d8ee;
	--fuse-primary-200-rgb: 153, 216, 238;
	--fuse-primary-300: #66c3e4;
	--fuse-primary-300-rgb: 102, 195, 228;
	--fuse-primary-400: #33aeda;
	--fuse-primary-400-rgb: 51, 174, 218;
	--fuse-primary-500: #0094ce;
	--fuse-primary-500-rgb: 0, 148, 206;
	--fuse-primary-600: #0085b9;
	--fuse-primary-600-rgb: 0, 133, 185;
	--fuse-primary-700: #00709b;
	--fuse-primary-700-rgb: 0, 112, 155;
	--fuse-primary-800: #005c7e;
	--fuse-primary-800-rgb: 0, 92, 126;
	--fuse-primary-900: #004961;
	--fuse-primary-900-rgb: 0, 73, 97;
	--fuse-primary-950: #002e3f;
	--fuse-primary-950-rgb: 0, 46, 63;
	--fuse-primary: #0094ce;
	--fuse-primary-rgb: 0, 148, 206;

    --fuse-on-primary-50: #1e1b4b;
    --fuse-on-primary-50-rgb: 30,27,75;
    --fuse-on-primary-100: #1e1b4b;
    --fuse-on-primary-100-rgb: 30,27,75;
    --fuse-on-primary-200: #1e1b4b;
    --fuse-on-primary-200-rgb: 30,27,75;
    --fuse-on-primary-300: #1e1b4b;
    --fuse-on-primary-300-rgb: 30,27,75;
    --fuse-on-primary-400: #1e1b4b;
    --fuse-on-primary-400-rgb: 30,27,75;
    --fuse-on-primary-500: #FFFFFF;
    --fuse-on-primary-500-rgb: 255,255,255;
    --fuse-on-primary-600: #FFFFFF;
    --fuse-on-primary-600-rgb: 255,255,255;
    --fuse-on-primary-700: #FFFFFF;
    --fuse-on-primary-700-rgb: 255,255,255;
    --fuse-on-primary-800: #FFFFFF;
    --fuse-on-primary-800-rgb: 255,255,255;
    --fuse-on-primary-900: #FFFFFF;
    --fuse-on-primary-900-rgb: 255,255,255;
    --fuse-on-primary-950: #FFFFFF;
    --fuse-on-primary-950-rgb: 255,255,255;
    --fuse-on-primary: #FFFFFF;
    --fuse-on-primary-rgb: 255,255,255;
    --fuse-accent-50: #f8fafc;
    --fuse-accent-50-rgb: 248,250,252;
    --fuse-accent-100: #f1f5f9;
    --fuse-accent-100-rgb: 241,245,249;
    --fuse-accent-200: #e2e8f0;
    --fuse-accent-200-rgb: 226,232,240;
    --fuse-accent-300: #cbd5e1;
    --fuse-accent-300-rgb: 203,213,225;
    --fuse-accent-400: #94a3b8;
    --fuse-accent-400-rgb: 148,163,184;
    --fuse-accent-500: #64748b;
    --fuse-accent-500-rgb: 100,116,139;
    --fuse-accent-600: #475569;
    --fuse-accent-600-rgb: 71,85,105;
    --fuse-accent-700: #334155;
    --fuse-accent-700-rgb: 51,65,85;
    --fuse-accent-800: #1e293b;
    --fuse-accent-800-rgb: 30,41,59;
    --fuse-accent-900: #0f172a;
    --fuse-accent-900-rgb: 15,23,42;
    --fuse-accent-950: #020617;
    --fuse-accent-950-rgb: 2,6,23;
    --fuse-accent: #1e293b;
    --fuse-accent-rgb: 30,41,59;
    --fuse-on-accent-50: #020617;
    --fuse-on-accent-50-rgb: 2,6,23;
    --fuse-on-accent-100: #020617;
    --fuse-on-accent-100-rgb: 2,6,23;
    --fuse-on-accent-200: #020617;
    --fuse-on-accent-200-rgb: 2,6,23;
    --fuse-on-accent-300: #020617;
    --fuse-on-accent-300-rgb: 2,6,23;
    --fuse-on-accent-400: #020617;
    --fuse-on-accent-400-rgb: 2,6,23;
    --fuse-on-accent-500: #FFFFFF;
    --fuse-on-accent-500-rgb: 255,255,255;
    --fuse-on-accent-600: #FFFFFF;
    --fuse-on-accent-600-rgb: 255,255,255;
    --fuse-on-accent-700: #FFFFFF;
    --fuse-on-accent-700-rgb: 255,255,255;
    --fuse-on-accent-800: #FFFFFF;
    --fuse-on-accent-800-rgb: 255,255,255;
    --fuse-on-accent-900: #FFFFFF;
    --fuse-on-accent-900-rgb: 255,255,255;
    --fuse-on-accent-950: #FFFFFF;
    --fuse-on-accent-950-rgb: 255,255,255;
    --fuse-on-accent: #FFFFFF;
    --fuse-on-accent-rgb: 255,255,255;
    --fuse-warn-50: #fef2f2;
    --fuse-warn-50-rgb: 254,242,242;
    --fuse-warn-100: #fee2e2;
    --fuse-warn-100-rgb: 254,226,226;
    --fuse-warn-200: #fecaca;
    --fuse-warn-200-rgb: 254,202,202;
    --fuse-warn-300: #fca5a5;
    --fuse-warn-300-rgb: 252,165,165;
    --fuse-warn-400: #f87171;
    --fuse-warn-400-rgb: 248,113,113;
    --fuse-warn-500: #ef4444;
    --fuse-warn-500-rgb: 239,68,68;
    --fuse-warn-600: #dc2626;
    --fuse-warn-600-rgb: 220,38,38;
    --fuse-warn-700: #b91c1c;
    --fuse-warn-700-rgb: 185,28,28;
    --fuse-warn-800: #991b1b;
    --fuse-warn-800-rgb: 153,27,27;
    --fuse-warn-900: #7f1d1d;
    --fuse-warn-900-rgb: 127,29,29;
    --fuse-warn-950: #450a0a;
    --fuse-warn-950-rgb: 69,10,10;
    --fuse-warn: #dc2626;
    --fuse-warn-rgb: 220,38,38;
    --fuse-on-warn-50: #450a0a;
    --fuse-on-warn-50-rgb: 69,10,10;
    --fuse-on-warn-100: #450a0a;
    --fuse-on-warn-100-rgb: 69,10,10;
    --fuse-on-warn-200: #450a0a;
    --fuse-on-warn-200-rgb: 69,10,10;
    --fuse-on-warn-300: #450a0a;
    --fuse-on-warn-300-rgb: 69,10,10;
    --fuse-on-warn-400: #450a0a;
    --fuse-on-warn-400-rgb: 69,10,10;
    --fuse-on-warn-500: #fef2f2;
    --fuse-on-warn-500-rgb: 254,242,242;
    --fuse-on-warn-600: #FFFFFF;
    --fuse-on-warn-600-rgb: 255,255,255;
    --fuse-on-warn-700: #FFFFFF;
    --fuse-on-warn-700-rgb: 255,255,255;
    --fuse-on-warn-800: #FFFFFF;
    --fuse-on-warn-800-rgb: 255,255,255;
    --fuse-on-warn-900: #FFFFFF;
    --fuse-on-warn-900-rgb: 255,255,255;
    --fuse-on-warn-950: #FFFFFF;
    --fuse-on-warn-950-rgb: 255,255,255;
    --fuse-on-warn: #FFFFFF;
    --fuse-on-warn-rgb: 255,255,255
}

body.light,.light,.dark .light {
    --is-dark: false;
    --fuse-bg-app-bar: #FFFFFF;
    --fuse-bg-app-bar-rgb: 255,255,255;
    --fuse-bg-card: #FFFFFF;
    --fuse-bg-card-rgb: 255,255,255;
    --fuse-bg-default: #f1f5f9;
    --fuse-bg-default-rgb: 241,245,249;
    --fuse-bg-dialog: #FFFFFF;
    --fuse-bg-dialog-rgb: 255,255,255;
    --fuse-bg-hover: rgba(148,163,184,.12);
    --fuse-bg-hover-rgb: 148,163,184;
    --fuse-bg-status-bar: #cbd5e1;
    --fuse-bg-status-bar-rgb: 203,213,225;
    --fuse-text-default: #1e293b;
    --fuse-text-default-rgb: 30,41,59;
    --fuse-text-secondary: #64748b;
    --fuse-text-secondary-rgb: 100,116,139;
    --fuse-text-hint: #94a3b8;
    --fuse-text-hint-rgb: 148,163,184;
    --fuse-text-disabled: #94a3b8;
    --fuse-text-disabled-rgb: 148,163,184;
    --fuse-border: #e2e8f0;
    --fuse-border-rgb: 226,232,240;
    --fuse-divider: #e2e8f0;
    --fuse-divider-rgb: 226,232,240;
    --fuse-icon: #64748b;
    --fuse-icon-rgb: 100,116,139;
    --fuse-mat-icon: #64748b;
    --fuse-mat-icon-rgb: 100,116,139
}

body.dark,
.dark,
.light .dark {
    --fuse-bg-app-bar: #0f172a;
    --fuse-bg-app-bar-rgb: 15,23,42;
    --fuse-bg-card: #1e293b;
    --fuse-bg-card-rgb: 30,41,59;
    --fuse-bg-default: #0f172a;
    --fuse-bg-default-rgb: 15,23,42;
    --fuse-bg-dialog: #1e293b;
    --fuse-bg-dialog-rgb: 30,41,59;
    --fuse-bg-hover: rgba(255, 255, 255, .05);
    --fuse-bg-hover-rgb: 255,255,255;
    --fuse-bg-status-bar: #0f172a;
    --fuse-bg-status-bar-rgb: 15,23,42;
    --fuse-text-default: #FFFFFF;
    --fuse-text-default-rgb: 255,255,255;
    --fuse-text-secondary: #94a3b8;
    --fuse-text-secondary-rgb: 148,163,184;
    --fuse-text-hint: #64748b;
    --fuse-text-hint-rgb: 100,116,139;
    --fuse-text-disabled: #475569;
    --fuse-text-disabled-rgb: 71,85,105;
    --fuse-border: rgba(241,245,249,.12);
    --fuse-border-rgb: 241,245,249;
    --fuse-divider: rgba(241,245,249,.12);
    --fuse-divider-rgb: 241,245,249;
    --fuse-icon: #94a3b8;
    --fuse-icon-rgb: 148,163,184;
    --fuse-mat-icon: #94a3b8;
    --fuse-mat-icon-rgb: 148,163,184
}