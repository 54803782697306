.h-0 {
    height: 0px!important
}

.h-0\.5 {
    height: .125rem!important
}

.h-1 {
    height: .25rem!important
}

.h-1\.5 {
    height: .375rem!important
}

.h-1\/3 {
    height: 33.333333%!important
}

.h-10 {
    height: 2.5rem!important
}

.h-11 {
    height: 2.75rem!important
}

.h-12 {
    height: 3rem!important
}

.h-120 {
    height: 30rem!important
}

.h-13 {
    height: 3.25rem!important
}

.h-14 {
    height: 3.5rem!important
}

.h-15 {
    height: 3.75rem!important
}

.h-16 {
    height: 4rem!important
}

.h-160 {
    height: 40rem!important
}

.h-18 {
    height: 4.5rem!important
}

.h-192 {
    height: 48rem!important
}

.h-2 {
    height: .5rem!important
}

.h-2\.5 {
    height: .625rem!important
}

.h-2\/4 {
    height: 50%!important
}

.h-20 {
    height: 5rem!important
}

.h-24 {
    height: 6rem!important
}

.h-3 {
    height: .75rem!important
}

.h-30 {
    height: 7.5rem!important
}

.h-32 {
    height: 8rem!important
}

.h-4 {
    height: 1rem!important
}

.h-40 {
    height: 10rem!important
}

.h-400 {
    height: 100rem!important
}

.h-44 {
    height: 11rem!important
}

.h-5 {
    height: 1.25rem!important
}

.h-6 {
    height: 1.5rem!important
}

.h-60 {
    height: 15rem!important
}

.h-7 {
    height: 1.75rem!important
}

.h-8 {
    height: 2rem!important
}

.h-80 {
    height: 20rem!important
}

.h-96 {
    height: 24rem!important
}

.h-auto {
    height: auto!important
}

.h-full {
    height: 100%!important
}

.max-h-120 {
    max-height: 30rem!important
}

.max-h-128 {
    max-height: 32rem!important
}

.max-h-160 {
    max-height: 40rem!important
}

.max-h-30 {
    max-height: 7.5rem!important
}

.max-h-40 {
    max-height: 10rem!important
}

.max-h-400 {
    max-height: 100rem!important
}

.max-h-64 {
    max-height: 16rem!important
}

.max-h-8 {
    max-height: 2rem!important
}

.max-h-none {
    max-height: none!important
}

.max-h-screen {
    max-height: 100vh!important
}

.min-h-10 {
    min-height: 2.5rem!important
}

.min-h-16 {
    min-height: 4rem!important
}

.min-h-20 {
    min-height: 5rem!important
}

.min-h-30 {
    min-height: 7.5rem!important
}

.min-h-400 {
    min-height: 100rem!important
}

.min-h-5 {
    min-height: 1.25rem!important
}

.min-h-6 {
    min-height: 1.5rem!important
}

.min-h-7 {
    min-height: 1.75rem!important
}

.min-h-8 {
    min-height: 2rem!important
}

.min-h-80 {
    min-height: 20rem!important
}

.min-h-100 {
    min-height: 25rem!important
}
.min-h-120 {
    min-height: 30rem!important
}

.min-h-full {
    min-height: 100%!important
}

.w-0 {
    width: 0px!important
}

.w-0\.5 {
    width: .125rem!important
}

.w-1 {
    width: .25rem!important
}

.w-1\.5 {
    width: .375rem!important
}

.w-1\/2 {
    width: 50%!important
}

.w-1\/3 {
    width: 33.333333%!important
}

.w-1\/4 {
    width: 25%!important
}

.w-10 {
    width: 2.5rem!important
}

.w-12 {
    width: 3rem!important
}

.w-14 {
    width: 3.5rem!important
}

.w-16 {
    width: 4rem!important
}

.w-2 {
    width: .5rem!important
}

.w-2\/3 {
    width: 66.666667%!important
}

.w-20 {
    width: 5rem!important
}

.w-24 {
    width: 6rem!important
}

.w-240 {
    width: 60rem!important
}

.w-3 {
    width: .75rem!important
}

.w-3\/4 {
    width: 75%!important
}

.w-30 {
    width: 7.5rem!important
}

.w-32 {
    width: 8rem!important
}

.w-4 {
    width: 1rem!important
}

.w-40 {
    width: 10rem!important
}

.w-48 {
    width: 12rem!important
}

.w-5 {
    width: 1.25rem!important
}

.w-56 {
    width: 14rem!important
}

.w-6 {
    width: 1.5rem!important
}

.w-60 {
    width: 15rem!important
}

.w-64 {
    width: 16rem!important
}

.w-7 {
    width: 1.75rem!important
}

.w-72 {
    width: 18rem!important
}

.w-8 {
    width: 2rem!important
}

.w-80 {
    width: 20rem!important
}

.w-90 {
    width: 22.5rem!important
}

.w-full {
    width: 100%!important
}

.w-screen {
    width: 100vw!important
}

.min-w-0 {
    min-width: 0px!important
}

.min-w-10 {
    min-width: 2.5rem!important
}

.min-w-12 {
    min-width: 3rem!important
}

.min-w-14 {
    min-width: 3.5rem!important
}

.min-w-18 {
    min-width: 4.5rem!important
}

.min-w-20 {
    min-width: 5rem!important
}

.min-w-240 {
    min-width: 60rem!important
}

.min-w-30 {
    min-width: 7.5rem!important
}

.min-w-36 {
    min-width: 9rem!important
}

.min-w-4 {
    min-width: 1rem!important
}

.min-w-40 {
    min-width: 10rem!important
}

.min-w-50 {
    min-width: 12.5rem!important
}

.min-w-6 {
    min-width: 1.5rem!important
}

.min-w-60 {
    min-width: 15rem!important
}

.min-w-64 {
    min-width: 16rem!important
}

.min-w-72 {
    min-width: 18rem!important
}

.min-w-80 {
    min-width: 20rem!important
}

.min-w-max {
    min-width: max-content!important
}

.min-w-screen {
    min-width: 100vw!important
}

.max-w-10 {
    max-width: 2.5rem!important
}

.max-w-100 {
    max-width: 25rem!important
}

.max-w-12 {
    max-width: 3rem!important
}

.max-w-140 {
    max-width: 35rem!important
}

.max-w-160 {
    max-width: 40rem!important
}

.max-w-24 {
    max-width: 6rem!important
}

.max-w-240 {
    max-width: 60rem!important
}

.max-w-2xl {
    max-width: 42rem!important
}

.max-w-3\/4 {
    max-width: 75%!important
}

.max-w-32 {
    max-width: 8rem!important
}

.max-w-320 {
    max-width: 80rem!important
}

.max-w-36 {
    max-width: 9rem!important
}

.max-w-360 {
    max-width: 90rem!important
}

.max-w-3xl {
    max-width: 48rem!important
}

.max-w-400 {
    max-width: 100rem!important
}

.max-w-4xl {
    max-width: 56rem!important
}

.max-w-5xl {
    max-width: 64rem!important
}

.max-w-6xl {
    max-width: 72rem!important
}

.max-w-60 {
    max-width: 15rem!important
}

.max-w-72 {
    max-width: 18rem!important
}

.max-w-7xl {
    max-width: 80rem!important
}

.max-w-80 {
    max-width: 20rem!important
}

.max-w-none {
    max-width: none!important
}

.max-w-screen-xl {
    max-width: 1440px!important
}

.max-w-sm {
    max-width: 24rem!important
}

.max-w-xl {
    max-width: 36rem!important
}

.max-w-xs {
    max-width: 20rem!important
}

@media (min-width: 600px) {

	.sm\:h-20 {
		height: 5rem!important
	}

	.sm\:h-48 {
		height: 12rem!important
	}

	.sm\:h-full {
		height: 100%!important
	}

	.sm\:max-h-120 {
		max-height: 30rem!important
	}

	.sm\:w-100 {
		width: 25rem!important
	}

	.sm\:w-128 {
		width: 32rem!important
	}

	.sm\:w-36 {
		width: 9rem!important
	}

	.sm\:w-72 {
		width: 18rem!important
	}

	.sm\:w-80 {
		width: 20rem!important
	}

	.sm\:w-90 {
		width: 22.5rem!important
	}

	.sm\:w-96 {
		width: 24rem!important
	}

	.sm\:w-auto {
		width: auto!important
	}

	.sm\:min-w-100 {
		min-width: 25rem!important
	}

	.sm\:min-w-80 {
		min-width: 20rem!important
	}

	.sm\:min-w-90 {
		min-width: 22.5rem!important
	}

	.sm\:max-w-120 {
		max-width: 30rem!important
	}

	.sm\:max-w-360 {
		max-width: 90rem!important
	}

	.sm\:max-w-40 {
		max-width: 10rem!important
	}

	.sm\:max-w-5xl {
		max-width: 64rem!important
	}

	.sm\:max-w-none {
		max-width: none!important
	}
}