.overflow-auto {
    overflow: auto!important
}

.overflow-hidden {
    overflow: hidden!important
}

.overflow-visible {
    overflow: visible!important
}

.overflow-x-auto {
    overflow-x: auto!important
}

.overflow-y-auto {
    overflow-y: auto!important
}

.overflow-y-hidden {
    overflow-y: hidden!important
}

.overflow-y-visible {
    overflow-y: visible!important
}

.overscroll-y-contain {
    overscroll-behavior-y: contain!important
}