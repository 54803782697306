.ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)!important
}

.ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)!important
}

.ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)!important
}

.ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)!important
}

.ring-inset {
    --tw-ring-inset: inset !important
}

.ring-black {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity)) !important
}

.ring-gray-300 {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgb(203 213 225 / var(--tw-ring-opacity)) !important
}

.ring-gray-600 {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgb(71 85 105 / var(--tw-ring-opacity)) !important
}

.ring-gray-800 {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgb(30 41 59 / var(--tw-ring-opacity)) !important
}

.ring-primary {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(var(--fuse-primary-rgb), var(--tw-ring-opacity)) !important
}

.ring-primary-50 {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(var(--fuse-primary-50-rgb), var(--tw-ring-opacity)) !important
}

.ring-transparent {
    --tw-ring-color: transparent !important
}

.ring-warn-400 {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(var(--fuse-warn-400-rgb), var(--tw-ring-opacity)) !important
}

.ring-warn-600 {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(var(--fuse-warn-600-rgb), var(--tw-ring-opacity)) !important
}

.ring-white {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity)) !important
}

.ring-opacity-10 {
    --tw-ring-opacity: .1 !important
}

.ring-offset-1 {
    --tw-ring-offset-width: 1px !important
}

.ring-offset-gray-800 {
    --tw-ring-offset-color: #1e293b !important
}

.ring-offset-transparent {
    --tw-ring-offset-color: transparent !important
}

.group:hover .group-hover\:ring-gray-400 {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity)) !important
}