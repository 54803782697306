.rounded {
    border-radius: .25rem!important
}

.rounded-2xl {
    border-radius: 1rem!important
}

.rounded-full {
    border-radius: 9999px!important
}

.rounded-lg {
    border-radius: .5rem!important
}

.rounded-md {
    border-radius: .375rem!important
}

.rounded-none {
    border-radius: 0!important
}

.rounded-sm {
    border-radius: .125rem!important
}

.rounded-xl {
    border-radius: .75rem!important
}

.rounded-b {
    border-bottom-right-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important
}

.rounded-l {
    border-top-left-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important
}

.rounded-l-2xl {
    border-top-left-radius: 1rem!important;
    border-bottom-left-radius: 1rem!important
}

.rounded-l-lg {
    border-top-left-radius: .5rem!important;
    border-bottom-left-radius: .5rem!important
}

.rounded-l-xl {
    border-top-left-radius: .75rem!important;
    border-bottom-left-radius: .75rem!important
}

.rounded-r-xl {
    border-top-right-radius: .75rem!important;
    border-bottom-right-radius: .75rem!important
}

.rounded-t-2xl {
    border-top-left-radius: 1rem!important;
    border-top-right-radius: 1rem!important
}

.rounded-t-xl {
    border-top-left-radius: .75rem!important;
    border-top-right-radius: .75rem!important
}

.sm\:rounded-2xl {
	border-radius: 1rem!important
}

.sm\:rounded-lg {
	border-radius: .5rem!important
}

.sm\:rounded-xl {
	border-radius: .75rem!important
}

.sm\:rounded-t-xl {
	border-top-left-radius: .75rem!important;
	border-top-right-radius: .75rem!important
}

.border {
    border-width: 1px!important
}

.border-0 {
    border-width: 0px!important
}

.border-2 {
    border-width: 2px!important
}

.border-b {
    border-bottom-width: 1px!important
}

.border-b-0 {
    border-bottom-width: 0px!important
}

.border-b-2 {
    border-bottom-width: 2px!important
}

.border-b-4 {
    border-bottom-width: 4px!important
}

.border-l {
    border-left-width: 1px!important
}

.border-l-2 {
    border-left-width: 2px!important
}

.border-l-4 {
    border-left-width: 4px!important
}

.border-r {
    border-right-width: 1px!important
}

.border-r-0 {
    border-right-width: 0px!important
}

.border-r-2 {
    border-right-width: 2px!important
}

.border-t {
    border-top-width: 1px!important
}

.border-t-2 {
    border-top-width: 2px!important
}

.border-dashed {
    border-style: dashed!important
}

.border-none {
    border-style: none!important
}

.border-accent {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-accent-rgb),var(--tw-border-opacity))!important
}

.border-blue-500 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(59 130 246 / var(--tw-border-opacity))!important
}

.border-gray-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(203 213 225 / var(--tw-border-opacity))!important
}

.border-gray-300\/70 {
    border-color: #cbd5e1b3!important
}

.border-gray-400 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(148 163 184 / var(--tw-border-opacity))!important
}

.border-gray-500 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(100 116 139 / var(--tw-border-opacity))!important
}

.border-gray-700 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(51 65 85 / var(--tw-border-opacity))!important
}

.border-green-400 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(74 222 128 / var(--tw-border-opacity))!important
}

.border-green-500 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(34 197 94 / var(--tw-border-opacity))!important
}

.border-on-accent {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-on-accent-rgb),var(--tw-border-opacity))!important
}

.border-on-accent-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-on-accent-300-rgb),var(--tw-border-opacity))!important
}

.border-on-primary {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-on-primary-rgb),var(--tw-border-opacity))!important
}

.border-on-primary-200 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-on-primary-200-rgb),var(--tw-border-opacity))!important
}

.border-on-warn {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-on-warn-rgb),var(--tw-border-opacity))!important
}

.border-on-warn-400 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-on-warn-400-rgb),var(--tw-border-opacity))!important
}

.border-primary {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-primary-rgb),var(--tw-border-opacity))!important
}

.border-purple-400 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(192 132 252 / var(--tw-border-opacity))!important
}

.border-slate-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(203 213 225 / var(--tw-border-opacity))!important
}

.border-transparent {
    border-color: transparent!important
}

.border-warn {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-warn-rgb),var(--tw-border-opacity))!important
}

.border-r-gray-300 {
    --tw-border-opacity: 1 !important;
    border-right-color: rgb(203 213 225 / var(--tw-border-opacity))!important
}

.border-r-gray-500 {
    --tw-border-opacity: 1 !important;
    border-right-color: rgb(100 116 139 / var(--tw-border-opacity))!important
}

.border-opacity-100 {
    --tw-border-opacity: 1 !important
}

.border-opacity-50 {
    --tw-border-opacity: .5 !important
}

.divide-x>:not([hidden])~:not([hidden]) {
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--tw-divide-x-reverse))!important;
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))!important
}

.divide-y>:not([hidden])~:not([hidden]) {
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)))!important;
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))!important
}


.first\:border-0:first-child {
    border-width: 0px!important
}

.even\:border-r-0:nth-child(2n) {
    border-right-width: 0px!important
}

.focus-within\:border-primary:focus-within {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-primary-rgb),var(--tw-border-opacity))!important
}

.focus\:border-primary:focus {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-primary-rgb),var(--tw-border-opacity))!important
}

@media (min-width: 960px) {
	.md\:border-none {
		border-style: none !important
	}
}